import {
  MapChannelKey,
  Meta,
  urls,
} from '@motional-cc/fe/interface/api/deployment-server';
import { Vehicle } from '@motional-cc/fe/interface/api/registrar';
import { deploymentServer } from 'src/api/hosts';
import { useApi } from './hooks/service';
import { convertToAbsolutePaths } from './utils';

export const deploymentServerPaths = convertToAbsolutePaths(
  deploymentServer,
  urls,
);

const useIsProductionMapKey = (
  mapChannelKey: MapChannelKey | null | undefined,
) => {
  const response = useApi<Meta.MetaListStableMapChannels.ResponseBody>(
    deploymentServerPaths.Meta.MetaListStableMapChannels(),
  );
  const safeChannels = response?.result;
  let isProductionMapKey;

  if (!mapChannelKey) {
    isProductionMapKey = false;
  } else if (!Array.isArray(safeChannels)) {
    isProductionMapKey = null;
  } else {
    isProductionMapKey = safeChannels.some(
      (safeChannel) => safeChannel.key === mapChannelKey,
    );
  }

  return {
    ...response,
    isProductionMapKey,
    safeChannels,
  };
};

const useHasProductionMap = (vehicle: Vehicle | null | undefined) => {
  const mapDeploymentChannel =
    (vehicle &&
      'bundle' in vehicle &&
      vehicle.bundle &&
      'map_deployment_channel' in vehicle.bundle &&
      vehicle.bundle.map_deployment_channel) ||
    null;

  const response = useIsProductionMapKey(mapDeploymentChannel);

  return {
    hasProductionMap: response.isProductionMapKey,
    ...response,
  };
};

export const deploymentServerApi = {
  useIsProductionMapKey,
  useHasProductionMap,
};

const EARTH_RADIUS_KM = 6371.0088;

function degrees2Radians(degrees: number) {
  return degrees * (Math.PI / 180);
}

// The haversine formulas are use to  determine the great-circle,
//   (the largest circle that can be drawn on any given sphere),
//   distance between two points on a sphere given their longitudes and latitudes.

// The haversine function computes half a versine,
//   (1 minus its cosine), of the angle between the 2 points
function haversine(
  latitudeRadiansA: number,
  latitudeRadiansB: number,
  longitudeRadiansA: number,
  longitudeRadiansB: number,
) {
  const deltaLatitude = latitudeRadiansB - latitudeRadiansA;
  const deltaLongitude = longitudeRadiansB - longitudeRadiansA;

  return (
    Math.sin(deltaLatitude / 2) * Math.sin(deltaLatitude / 2) +
    Math.cos(latitudeRadiansA) *
      Math.cos(latitudeRadiansB) *
      Math.sin(deltaLongitude / 2) *
      Math.sin(deltaLongitude / 2)
  );
}

// To calculate a normalised distance based on the angle,
//   we apply the archaversine (inverse haversine)
function archaversine(halfVersine: number) {
  return 2 * Math.atan2(Math.sqrt(halfVersine), Math.sqrt(1 - halfVersine));
}

export function distanceBetween2LngLatsKm(
  lngLatADegrees: number[],
  lngLatBDegrees: number[],
) {
  const [lngA, latA] = lngLatADegrees;
  const [lngB, latB] = lngLatBDegrees;
  const halfVersine = haversine(
    degrees2Radians(latA),
    degrees2Radians(latB),
    degrees2Radians(lngA),
    degrees2Radians(lngB),
  );
  const normalisedDistance = archaversine(halfVersine);
  return EARTH_RADIUS_KM * normalisedDistance;
}

import { ReactElement } from 'react';
import ButtonField from 'src/components/common/ButtonField';
import {
  CellProps,
  ColumnComponentDefinition,
} from 'src/components/common/Table/interface';
import TableCell, {
  CONTENT_CLASS,
} from 'src/components/common/Table/TableCell';
import Tooltip from 'src/components/common/Tooltip';
import './ActionCell.scss';

interface ActionColumn extends ColumnComponentDefinition {
  action: (value: unknown) => void;
  actionIcon?: ReactElement;
  actionLabel?: string;
  actionTooltip?: string;
  showLabelAndIcon?: boolean;
  isDisabled?: boolean;
}

interface Props extends CellProps<unknown> {
  column: ActionColumn;
}

function ActionCell({
  column,
  column: {
    action,
    actionIcon,
    actionLabel,
    actionTooltip,
    showLabelAndIcon,
    isDisabled,
  },
  cell,
}: Props) {
  const handleButtonClick = () => {
    action(cell);
  };

  return (
    <TableCell
      column={column}
      className="action-table-cell the-table__cell--action"
    >
      <div className={CONTENT_CLASS}>
        <Tooltip content={actionTooltip || actionLabel}>
          <ButtonField
            insetFocus
            className="action-table-cell__button"
            color="text"
            aria-label={actionLabel}
            onClick={handleButtonClick}
            disabled={isDisabled}
            startIcon={actionIcon}
            label={!actionIcon || showLabelAndIcon ? actionLabel : ''}
          />
        </Tooltip>
      </div>
    </TableCell>
  );
}

export default ActionCell;

import {
  Children,
  cloneElement,
  ReactElement,
  useEffect,
  useState,
} from 'react';

interface Props {
  children: ReactElement;
  when?: boolean;
}

function ScrollIntoView({ children, when = true }: Props) {
  const [childRef, setChildRef] = useState<HTMLElement>();
  const child = Children.only(children);

  useEffect(
    function scrollChildIntoView() {
      if (!when || !childRef) return;

      childRef?.scrollIntoView({ block: 'nearest' });
    },
    [when, childRef],
  );

  return cloneElement(child, {
    ref: setChildRef,
  });
}

export default ScrollIntoView;

import { Model } from '@motional-cc/fe/interface/api/registrar';
import Audi from 'src/static/img/audi_profile@2x.png';
import BMW from 'src/static/img/bmw_profile@2x.png';
import Ioniq from 'src/static/img/ioniq_profile@2x.png';
import Pacifica from 'src/static/img/pacifica_profile@2x.png';
import Zoe from 'src/static/img/zoe_profile@2x.png';
import Eval from 'src/static/img/eval_profile@2x.png';

const CAR_PROFILE_IMAGES: Readonly<{ [model in Model]: string }> = {
  BMW,
  Pacifica,
  Zoe,
  Audi,
  Ioniq,
  Eval,
} as const;

export default CAR_PROFILE_IMAGES;

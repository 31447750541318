import { rgb } from './rgb';
import { Color } from '.';

export type Rgba = [number, number, number, number];

export const isRgba = (color: Color): color is Rgba =>
  Array.isArray(color) && color.length === 4;

export const rgba = (
  color: Color,
  alpha = isRgba(color) ? color[3] : 1,
): Rgba => [...rgb(color), Math.max(0, Math.min(1, alpha))];

import { ReactNode, useMemo, useState } from 'react';
import {
  AutocompleteProps,
  SecondaryActionProps,
} from 'src/components/common/Select/interface';
import { FieldSpacing } from 'src/interface/command-center';
import { AllOrNoneOf } from 'src/interface/utility';
import { searchCompare } from 'src/tools/string/searchCompare';
import { CommonProps } from './interface';
import SelectField from './SelectField';
import { getOptionKey } from './utils';

const RenderValue = ({ children }: { children: ReactNode }) => (
  <span className="select-field__selected">
    {typeof children === 'string' ? (
      <span className="select-field__selected-label">{children}</span>
    ) : (
      children
    )}
  </span>
);

type Props<ItemType> = {
  value?: ItemType;
  className?: string;
  onSelect: (newValue?: ItemType) => void;
  allowClear?: boolean;
  spacing?: FieldSpacing;
  disabled?: boolean;
  infoMessage?: string;
  disabledOptions?: ItemType[];
  optionLabelPath?: string;
  useLocalSearch?: boolean;
} & CommonProps<ItemType> &
  AllOrNoneOf<SecondaryActionProps> &
  AllOrNoneOf<AutocompleteProps>;

function Select<ItemType>({
  value,
  className,
  options: optionsProps,
  optionKeyPath,
  optionLabelPath = optionKeyPath,
  onSelect,
  allowClear = false,
  renderListOption = (item: ItemType) =>
    getOptionKey(item, optionLabelPath) as string,
  renderSelectedOption = (item: ItemType) =>
    getOptionKey(item, optionLabelPath) as string,
  useLocalSearch,
  ...restProps
}: Props<ItemType>) {
  const [localSearchText, setLocalSearchText] = useState<string | undefined>();

  const options = useMemo(() => {
    if (!useLocalSearch || !localSearchText) {
      return optionsProps;
    }

    return optionsProps?.filter((option) =>
      searchCompare(renderSelectedOption(option), localSearchText),
    );
  }, [localSearchText, optionsProps, useLocalSearch, renderSelectedOption]);

  const handleItemSelect = (item: ItemType) => {
    onSelect(item);
  };

  const handleClear = () => {
    onSelect();
  };

  const handleRemoveLast = () => {
    onSelect();
  };

  const renderSelectedItems = (selectedOptions?: Readonly<ItemType[]>) => {
    const selectedItem = selectedOptions?.[0];
    return selectedItem ? (
      <RenderValue>{renderSelectedOption(selectedItem)}</RenderValue>
    ) : null;
  };

  if (useLocalSearch) {
    restProps.searchText = localSearchText ?? '';
    restProps.onSearchTextChange = setLocalSearchText;
  }

  return (
    <SelectField
      closeOnSelect
      optionKeyPath={optionKeyPath}
      className={className}
      options={options}
      selectedOptions={value ? [value] : []}
      onSelect={handleItemSelect}
      onClear={allowClear ? handleClear : undefined}
      onRemoveLast={handleRemoveLast}
      renderSelected={renderSelectedItems}
      renderListOption={renderListOption}
      renderSelectedOption={renderSelectedOption}
      {...restProps}
    />
  );
}

export default Select;

import { useEffect, useRef } from 'react';

export const useTimeout = (
  callback: () => void,
  duration: number,
  { enabled }: { enabled: boolean } = { enabled: true },
) => {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(
    function callCallbackAfterTimeout() {
      let timeoutId: number;
      if (enabled) {
        timeoutId = window.setTimeout(() => {
          callbackRef.current();
        }, duration);
      }
      return () => {
        if (timeoutId) {
          window.clearTimeout(timeoutId);
        }
      };
    },
    [enabled, duration],
  );
};

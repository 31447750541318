import { ReactElement, useCallback, useEffect, useState } from 'react';
import { PartialBy } from 'src/interface/utility';
import Modal, { Props as ModalBoxProps } from './Modal';

type ModalHookProps = PartialBy<ModalBoxProps, 'onClose'>;

export default function useModal() {
  const [ModalComponent, setModalComponent] = useState<
    (modal: ModalHookProps) => ReactElement | null
  >(() => () => null);
  const [isOpen, setOpen] = useState(false);

  const openModal = useCallback(() => {
    setOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(
    function setupModal() {
      function AModal({ onClose, children, ...modalProps }: ModalHookProps) {
        const handleCancelClick = () => {
          onClose?.();
          closeModal();
        };

        return (
          <Modal {...modalProps} isOpen={isOpen} onClose={handleCancelClick}>
            {children}
          </Modal>
        );
      }
      setModalComponent(() => AModal);
    },
    [closeModal, isOpen],
  );

  return {
    ModalComponent,
    openModal,
    closeModal,
    isOpen,
  };
}

import compact from 'lodash/compact';
import {
  Children,
  cloneElement,
  ReactChild,
  ReactElement,
  useCallback,
  useMemo,
} from 'react';
import { IS_RADIO_SYMBOL } from './Radio';

type AllowedReactElement = ReactElement | string | number | null | undefined;

interface Props<Value> {
  name: string;
  value: Value;
  children: AllowedReactElement[] | AllowedReactElement;
  onChange: (newValue: Value) => void;
}

function RadioGroup<Value>({ name, value, children, onChange }: Props<Value>) {
  const handleChange = useCallback(
    (newValue: Value) => {
      onChange(newValue);
    },
    [onChange],
  );

  const connectRadios = useCallback(
    (
      children: AllowedReactElement[] | AllowedReactElement,
    ): AllowedReactElement[] | AllowedReactElement => {
      const connectedChildren = compact(
        (Array.isArray(children) ? children : [children]).map(
          (child, index) => {
            if (!child) {
              return undefined;
            }

            if (typeof child === 'string' || typeof child === 'number') {
              return child;
            }

            const childName = child.props.name ?? name;
            if (
              child.type['render']?.['symbol'] === IS_RADIO_SYMBOL &&
              childName === name
            ) {
              return cloneElement(child, {
                ...child.props,
                key: child.key ?? index,
                name: childName,
                onChange: handleChange,
                checked: child.props.value === value,
              });
            }

            const children = Children.toArray(child.props.children);
            if (children.length > 0) {
              return cloneElement(child, {
                ...child.props,
                key: child.key,
                // TODO: work out how to remove `as ReactChild[]`
                children: connectRadios(children as ReactChild[] | ReactChild),
              });
            } else {
              return child;
            }
          },
        ),
      );
      if (connectedChildren.length === 1) {
        return connectedChildren[0];
      }
      return connectedChildren;
    },
    [name, value, handleChange],
  );

  const connectedChildren = useMemo(
    () => connectRadios(children),
    [children, connectRadios],
  );
  return <>{connectedChildren}</>;
}

export default RadioGroup;

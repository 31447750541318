import IconButton from 'src/components/common/IconButton';
import PanelHeader, {
  Props as PanelHeaderProps,
} from 'src/components/common/PanelHeader';
import './PanelHeaderWithClose.scss';

export type Props = Omit<PanelHeaderProps, 'primaryAction'> & {
  onClose: () => void;
};

function PanelHeaderWithClose({ onClose, ...props }: Props) {
  return (
    <PanelHeader
      {...props}
      primaryAction={
        <IconButton
          color="text"
          iconName="MultiplyFat"
          className="panel-header-with-close__close-button"
          onClick={onClose}
        />
      }
    />
  );
}

export default PanelHeaderWithClose;

import clsx from 'clsx';
import { KeyboardEvent, RefObject, forwardRef, useEffect } from 'react';
// import Icon from 'src/components/common/Icon';
import SearchField from 'src/components/common/SearchField';
import './SelectInput.scss';

type Props = {
  inputId?: string;
  label: string;
  value: string;
  onChange: (newValue: string) => void;
  className?: string;
  placeholder?: string;
};

function SelectInput(
  {
    inputId,
    label,
    value,
    onChange,
    className,
    placeholder = 'Type to search…',
  }: Props,
  ref: RefObject<HTMLInputElement> | null,
) {
  useEffect(
    function focusInput() {
      ref?.current?.focus();
    },
    [ref],
  );

  const handleKeyDown = (event: KeyboardEvent) => {
    if (
      ['ArrowUp', 'ArrowDown', 'Enter', 'Escape', 'Tab'].includes(event.key)
    ) {
      event.preventDefault();
    } else if (event.key === 'Backspace' && value === '') {
      event.preventDefault();
    } else {
      // don't propagate other keys to parent
      event.stopPropagation();
    }
  };

  return (
    <div className={clsx(['select-input', className])}>
      <SearchField
        ref={ref}
        hideLabel
        className="select-input__term-input"
        inputId={inputId}
        label={label}
        value={value}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
      />
    </div>
  );
}

export default forwardRef(SelectInput);

export var urls;
(function (urls) {
    let Devices;
    (function (Devices) {
        Devices.DeviceRegistration = () => `/v2/devices`;
    })(Devices = urls.Devices || (urls.Devices = {}));
    let Director;
    (function (Director) {
        Director.TargetsJsonList = () => `/v2/director/targets.json`;
        Director.RootJsonDetail = (fileNumber) => `/v2/director/${fileNumber}.root.json`;
        Director.DirectorEcus = () => `/v2/director/ecus`;
        Director.DirectorManifest = () => `/v2/director/manifest`;
    })(Director = urls.Director || (urls.Director = {}));
    let Events;
    (function (Events) {
        Events.EventsPost = () => `/v2/events`;
    })(Events = urls.Events || (urls.Events = {}));
    let Repo;
    (function (Repo) {
        Repo.RootJsonDetail = (fileNumber) => `/v2/repo/${fileNumber}.root.json`;
        Repo.TargetsJsonList = () => `/v2/repo/targets.json`;
        Repo.TimestampJsonList = () => `/v2/repo/timestamp.json`;
        Repo.SnapshotJsonList = () => `/v2/repo/snapshot.json`;
    })(Repo = urls.Repo || (urls.Repo = {}));
    let SystemInfo;
    (function (SystemInfo) {
        SystemInfo.SystemInfoNetwork = () => `/v2/system_info/network`;
    })(SystemInfo = urls.SystemInfo || (urls.SystemInfo = {}));
    let Meta;
    (function (Meta) {
        Meta.BundleSupportedFeatureFlags = (bundleName) => `/v2/meta/bundlefeatureflags/${bundleName}`;
        Meta.MetaComponents = () => `/v2/meta/components`;
        Meta.MetaTargets = () => `/v2/meta/targets`;
        Meta.MetaListAllMaps = () => `/v2/meta/maps`;
        Meta.MetaListMapSnapshots = (branchId, channel) => `/v2/meta/maps/branch/${branchId}/channel/${channel}/snapshots`;
        Meta.MetaListMapChannels = () => `/v2/meta/maps/channels`;
        Meta.MetaListStableMapChannels = () => `/v2/meta/maps/stable-channels`;
        Meta.MetaListUnstableMapChannels = () => `/v2/meta/maps/unstable-channels`;
    })(Meta = urls.Meta || (urls.Meta = {}));
    let Ota;
    (function (Ota) {
        Ota.CreateCycleRecord = (vehicleId) => `/v2/ota/one-touch/vehicles/${vehicleId}`;
        Ota.UpdateStepRecord = (vehicleId, cycleId) => `/v2/ota/one-touch/vehicles/${vehicleId}/cycles/${cycleId}`;
        Ota.CreateConfigCheckResult = () => `/v2/ota/one-touch/config-check-results`;
    })(Ota = urls.Ota || (urls.Ota = {}));
})(urls || (urls = {}));

import clsx from 'clsx';
import { CSSProperties, ReactNode } from 'react';
import './DefinitionList.scss';

type Props = {
  children: ReactNode;
  className?: string;
} & ({ horizontal: true | number } | { horizontal?: never });

function DefinitionList({ children, className, horizontal }: Props) {
  return (
    <dl
      style={
        {
          '--width-definition':
            typeof horizontal === 'number' ? horizontal : undefined,
        } as CSSProperties
      }
      className={clsx([
        className,
        'the-definition-list',
        `the-definition-list--direction-${
          horizontal ? 'horizontal' : 'vertical'
        }`,
      ])}
    >
      {children}
    </dl>
  );
}

export default DefinitionList;

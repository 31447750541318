import { forwardRef, RefObject } from 'react';
import Icon from 'src/components/common/Icon';
import TextField, { TextFieldProps } from './TextField';
import './SearchField.scss';

type Props = Omit<TextFieldProps, 'renderIcon'>;

function SearchField(
  { allowClear = true, ...remainingProps }: Props,
  ref: RefObject<HTMLInputElement>,
) {
  return (
    <TextField
      {...remainingProps}
      ref={ref}
      allowClear={allowClear}
      icon={<Icon name="MagnifyingGlass" />}
    />
  );
}

export default forwardRef(SearchField);

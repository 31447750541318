import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

interface Props {
  param: string;
  triggerValue?: string;
  triggerFunction?: () => void;
  keepParamAfterTriggering?: boolean;
}

export const useTriggerSearchParam = ({
  param,
  triggerValue,
  triggerFunction,
  keepParamAfterTriggering = false,
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const paramValue = searchParams.get(param);

  useEffect(
    function runTriggerFunction() {
      if (!triggerFunction || (triggerValue && paramValue !== triggerValue))
        return;

      triggerFunction?.();

      if (keepParamAfterTriggering) return;

      setSearchParams((oldState) => {
        return new URLSearchParams(
          [...oldState.entries()].filter(([key]) => key !== param),
        );
      });
    },
    [
      keepParamAfterTriggering,
      param,
      paramValue,
      setSearchParams,
      triggerFunction,
      triggerValue,
    ],
  );
};

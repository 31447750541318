import clsx from 'clsx';
import { Key, ReactNode, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import FullWidthLoader from 'src/components/common/FullWidthLoader';
import SelectListOption from './SelectListOption';
import { getOptionKey } from './utils';
import './SelectList.scss';

type Props<Option> = {
  selectedOptions: Readonly<Option[]>;
  disabledOptions?: Readonly<Option[]>;
  options: Readonly<Option[]>;
  highlightedOptionIndex: number;
  renderListOption?: (value: Option) => ReactNode;
  optionKeyPath?: string;
  className?: string;
  type?: 'checkbox' | 'radio';

  onScrollToBottom?: () => void;
  onOptionSelect?: (option: Option) => void;
  onOptionHighlight?: (option: Option) => void;
};

function SelectList<Option>({
  selectedOptions,
  disabledOptions,
  options,
  renderListOption,
  optionKeyPath,
  highlightedOptionIndex,
  onScrollToBottom,
  onOptionSelect,
  onOptionHighlight,
  className,
  type,
}: Props<Option>) {
  const [bottomRef, isBottom] = useInView();
  const [listElement, setListElement] = useState<HTMLUListElement | null>(null);

  useEffect(
    function showLoaderWhenReachingLastOption() {
      if (highlightedOptionIndex === options.length - 1) {
        listElement?.scrollTo?.(0, listElement.scrollHeight);
      }
    },
    [listElement, highlightedOptionIndex, options.length],
  );

  useEffect(
    function handleScrollToBottom() {
      if (isBottom) {
        onScrollToBottom?.();
      }
    },
    [isBottom, onScrollToBottom],
  );

  return (
    <ul
      ref={setListElement}
      role="listbox"
      className={clsx(['select-list', className])}
    >
      {options.map((option, index) => {
        const key = getOptionKey(option, optionKeyPath) as Key;
        return (
          <SelectListOption
            key={key}
            listElement={listElement ?? undefined}
            isHighlighted={highlightedOptionIndex === index}
            option={option}
            renderContent={renderListOption}
            onOptionSelect={onOptionSelect}
            onOptionHighlight={onOptionHighlight}
            type={type}
            isSelected={selectedOptions.some(
              (selectedOption) =>
                getOptionKey(selectedOption, optionKeyPath) === key,
            )}
            disabled={disabledOptions?.some(
              (selectedOption) =>
                getOptionKey(selectedOption, optionKeyPath) === key,
            )}
          />
        );
      })}

      {onScrollToBottom && (
        <li ref={bottomRef} className="select-list__loading">
          <FullWidthLoader className="select-list__loading-icon" />
        </li>
      )}
    </ul>
  );
}

export default SelectList;

import { ReactElement, useEffect, useState } from 'react';
import { PartialBy } from 'src/interface/utility';
import Dialog, { Props as DialogBoxProps } from './Dialog';

export type DialogHookProps = PartialBy<DialogBoxProps, 'onCancelClick'>;

export default function useDialog() {
  const [DialogComponent, setDialogComponent] = useState<
    (dialog: DialogHookProps) => ReactElement | null
  >(() => () => null);
  const [isOpen, setOpen] = useState(false);
  const [acceptIsRunning, setAcceptIsRunning] = useState(false);
  const [warningIsRunning, setWarningIsRunning] = useState(false);
  const isDisabled = acceptIsRunning || warningIsRunning;

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  useEffect(
    function setupDialog() {
      function ADialog({
        isOpen: isOpenProps,
        onAcceptClick,
        onWarningClick,
        onCancelClick,
        ...dialogProps
      }: DialogHookProps) {
        const handleWarningClick = onWarningClick
          ? async () => {
              try {
                setAcceptIsRunning(true);
                await onWarningClick();
              } catch (_e) {
                // TODO: support error handling
                // Callback? Or reqct-query-like error-saving?
              } finally {
                setAcceptIsRunning(false);
              }

              closeDialog();
            }
          : undefined;

        const handleAcceptClick = onAcceptClick
          ? async () => {
              try {
                setWarningIsRunning(true);
                await onAcceptClick();
              } catch (_e) {
                // TODO: support error handling
                // Callback? Or reqct-query-like error-saving?
              } finally {
                setWarningIsRunning(false);
              }

              closeDialog();
            }
          : undefined;

        const handleCancelClick = () => {
          onCancelClick?.();
          closeDialog();
        };

        return (
          <Dialog
            {...dialogProps}
            isOpen={isOpenProps ?? isOpen}
            isDisabled={isDisabled}
            onAcceptClick={handleAcceptClick}
            onWarningClick={handleWarningClick}
            onCancelClick={handleCancelClick}
          />
        );
      }
      setDialogComponent(() => ADialog);
    },
    [isDisabled, isOpen],
  );

  return {
    DialogComponent,
    openDialog,
    closeDialog,
    isOpen,
  };
}

import clsx from 'clsx';
import { ReactHTML, ReactNode } from 'react';
import { OneOf } from 'src/interface/utility';
import './FieldSet.scss';

export type PropsWithoutLabel = {
  children: ReactNode;
  className?: string;
  end?: boolean;
  element?: keyof ReactHTML;
} & OneOf<{
  vertical: true;
  horizontal: true;
}> &
  OneOf<{
    end: true;
    start?: true;
    fill: true;
  }>;

type Props = PropsWithoutLabel & {
  label: string;
  hideLabel?: boolean;
};

function FieldSet({
  children,
  className,
  vertical,
  end,
  fill,
  element: Element = 'fieldset',
  label,
  hideLabel,
}: Props) {
  return (
    <Element
      className={clsx([
        className,
        'field-set',
        end
          ? 'field-set--side-end'
          : fill
          ? 'field-set--side-fill'
          : 'field-set--side-start',
        vertical
          ? 'field-set--direction-vertical'
          : 'field-set--direction-horizontal',
        { 'field-set--hide-label': hideLabel },
      ])}
    >
      {Element === 'fieldset' && label && (
        <legend className="field-set__legend">{label}</legend>
      )}
      <div className="field-set__alignment">{children}</div>
    </Element>
  );
}

export default FieldSet;

import {
  Locations,
  urls,
} from '@motional-cc/fe/interface/api/vehicle-status-service';
import { useMemo } from 'react';
import { useApi } from 'src/api/hooks/service';
import { vehicleStatus } from 'src/api/hosts';
import { distanceBetween2LngLatsKm } from 'src/tools/map/distanceBetween2LngLatsKm';
import { convertToAbsolutePaths } from './utils';

export const fleetPaths = convertToAbsolutePaths(vehicleStatus, urls);

function getDetails(polygon: number[][] | undefined) {
  let minLat = Infinity;
  let maxLat = -Infinity;
  let minLng = Infinity;
  let maxLng = -Infinity;

  polygon?.forEach(([lng, lat]: number[]) => {
    minLat = Math.min(minLat, lat);
    maxLat = Math.max(maxLat, lat);
    minLng = Math.min(minLng, lng);
    maxLng = Math.max(maxLng, lng);
  });

  return {
    polygon,
    // This is the distance between 2 corners of the bounds of the region.
    // This gives us the largest size the region could be.
    boundsHypot: distanceBetween2LngLatsKm([minLat, minLng], [maxLat, maxLng]),
    min: {
      lat: minLat,
      lng: minLng,
    },
    max: {
      lat: maxLat,
      lng: maxLng,
    },
  };
}

const useRegionCoords = () => {
  const { result: boston } = useApi<Locations.Locations.ResponseBody>(
    fleetPaths.Locations.Locations('boston'),
  );
  const { result: singapore } = useApi<Locations.Locations.ResponseBody>(
    fleetPaths.Locations.Locations('singapore'),
  );
  const { result: las_vegas } = useApi<Locations.Locations.ResponseBody>(
    fleetPaths.Locations.Locations('las_vegas'),
  );
  const { result: pittsburgh } = useApi<Locations.Locations.ResponseBody>(
    fleetPaths.Locations.Locations('pittsburgh'),
  );
  const { result: santa_monica } = useApi<Locations.Locations.ResponseBody>(
    fleetPaths.Locations.Locations('santa_monica'),
  );
  const { result: san_diego } = useApi<Locations.Locations.ResponseBody>(
    fleetPaths.Locations.Locations('san_diego'),
  );
  const { result: austin } = useApi<Locations.Locations.ResponseBody>(
    fleetPaths.Locations.Locations('austin'),
  );

  const details = useMemo(
    () => ({
      boston: getDetails(boston),
      singapore: getDetails(singapore),
      las_vegas: getDetails(las_vegas),
      pittsburgh: getDetails(pittsburgh),
      santa_monica: getDetails(santa_monica),
      san_diego: getDetails(san_diego),
      austin: getDetails(austin),
    }),
    [boston, las_vegas, pittsburgh, singapore, santa_monica, san_diego, austin],
  );

  return useMemo(
    () => ({
      ...details,
      maxHypot:
        Math.max(
          ...Object.values(details)
            .map((details) => details.boundsHypot)
            .filter((hypot) => hypot !== Infinity),
        ) || 0,
    }),
    [details],
  );
};

export const fleetApi = {
  useRegionCoords,
};

import { Model as CarModelName } from '@motional-cc/fe/interface/api/registrar';
import clsx from 'clsx';
import { CAR_MODEL_NAMES } from 'src/interface/command-center';
import { Immutable } from 'src/interface/utility';
import VehicleModelRadio from './VehicleModelRadio';
import './SelectVehicleModel.scss';

type Props = {
  className?: string;
  value?: CarModelName;
  disabled?: boolean;
  isStatic?: boolean;
  autoFocus?: boolean;
  models?: Immutable<CarModelName[]>;
  onChange: (value: CarModelName) => void;
};

function SelectVehicleModel({
  className,
  value,
  disabled,
  isStatic,
  autoFocus,
  models = CAR_MODEL_NAMES,
  onChange,
}: Props) {
  const handleOnChange = (model: CarModelName) => (checked: boolean) => {
    if (checked) {
      onChange(model);
    }
  };

  return (
    <fieldset className={clsx(['select-vehicle-model', className])}>
      <div className="select-vehicle-model__focus-box">
        <legend className="select-vehicle-model__legend">
          Model<span className="select-vehicle-model__legend-asterisk">*</span>
        </legend>
        <div className="select-vehicle-model__list">
          {models.map((model, index) => (
            <VehicleModelRadio
              key={model}
              checked={value === model}
              onChange={handleOnChange(model)}
              model={model}
              disabled={disabled}
              isStatic={isStatic}
              // This works because later uses of autoFocus override previous ones
              autoFocus={(autoFocus && value === model) || index === 0}
            />
          ))}
        </div>
      </div>
    </fieldset>
  );
}

export default SelectVehicleModel;

const WARNING_COUNT_ID = 'outdated-browser-warning-count';
const warningShownCount =
  parseInt(String(localStorage.getItem(WARNING_COUNT_ID))) || 0;

export const alertAboutOutdatedBrowsers = () => {
  const missingFeatures: string[] = [];

  if (!('findLast' in Array.prototype)) {
    missingFeatures.push('Array.prototype.findLast');
  }

  if (!('findLastIndex' in Array.prototype)) {
    missingFeatures.push('Array.prototype.findLastIndex');
  }

  if (!('Intl' in globalThis)) {
    missingFeatures.push('Intl');
  }

  if (!globalThis?.Intl || !('ListFormat' in globalThis.Intl)) {
    missingFeatures.push('Intl.ListFormat');
  }

  if (missingFeatures.length) {
    if (warningShownCount % 10 === 0) {
      alert(
        `Please update your browser.\n
Your browser is ${
          warningShownCount ? 'still' : ''
        } outdated so some functionality may not work as expected, or the app may crash unexpectedly.\n\n
Specifically, we use the following features that it doesn’t support:\n  •${missingFeatures.join(
          '\n  •',
        )}\n`,
      );
    }

    localStorage.setItem(WARNING_COUNT_ID, `${warningShownCount + 1}`);
  } else {
    localStorage.removeItem(WARNING_COUNT_ID);
  }
};

import { FleetVehicle } from '@motional-cc/fe/interface/api/armada';
import clsx from 'clsx';
import { CellProps } from './interface';
import VehiclePartnersCell from './VehiclePartnersCell';
import './VehiclePartnerNamesCell.scss';

function VehiclePartnerNamesCell(props: CellProps<FleetVehicle>) {
  return (
    <VehiclePartnersCell
      {...props}
      PartnerComponent={({ partner }) => (
        <span
          className={clsx([
            'vehicle-partners-name-cell',
            `vehicle-partners-name-cell--status-${
              partner.isActive ? 'active' : 'inactive'
            }`,
          ])}
        >
          <i className="vehicle-partners-name-cell__status-dot" />
          {partner.label}
        </span>
      )}
    />
  );
}

export default VehiclePartnerNamesCell;

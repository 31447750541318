import { createRoot } from 'react-dom/client';
import App from 'src/screens/App';
import { alertAboutOutdatedBrowsers } from './tools/check-outdated-browser';
import { updateWindowSizeStyles } from './tools/window-size';
import 'src/index.scss';

window.global ??= window;
alertAboutOutdatedBrowsers();

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

const mountApp = () => {
  root.render(<App />);
};

async function startAppWithMockedNetwork() {
  const { worker } = await import('./mocks/browser');
  await worker.start({
    onUnhandledRequest: 'bypass',
    serviceWorker: { url: '/mockServiceWorker.js' },
  });
}

// Workaround: Currently, we cannot run 2 service workers,
// so we will not register for push notification when running "mocking mode"
// After fully mock (even okta), we can change to default port to avoid the service worker collision
// TODO: RTI-4005 find a way to merge the service workers / run two at once
if (import.meta.env.VITE_MSW_ENABLED) {
  startAppWithMockedNetwork().then(() => {
    mountApp();
  });
} else {
  mountApp();
}

updateWindowSizeStyles();

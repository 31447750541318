export var urls;
(function (urls) {
    let Fleets;
    (function (Fleets) {
        Fleets.FleetsList = () => `/fleets`;
        Fleets.FleetsCreate = () => `/fleets`;
        Fleets.FleetsDetail = (fleetId) => `/fleets/${fleetId}`;
        Fleets.FleetsUpdate = (fleetId) => `/fleets/${fleetId}`;
        Fleets.FleetsDelete = (fleetId) => `/fleets/${fleetId}`;
        Fleets.VehiclesDetail = (fleetId) => `/fleets/${fleetId}/vehicles`;
    })(Fleets = urls.Fleets || (urls.Fleets = {}));
    let Vehicles;
    (function (Vehicles) {
        Vehicles.VehiclesList = () => `/vehicles`;
    })(Vehicles = urls.Vehicles || (urls.Vehicles = {}));
    let Health;
    (function (Health) {
        Health.HealthList = () => `/health`;
    })(Health = urls.Health || (urls.Health = {}));
    let ServiceInfo;
    (function (ServiceInfo) {
        ServiceInfo.ServiceInfoList = () => `/service_info`;
    })(ServiceInfo = urls.ServiceInfo || (urls.ServiceInfo = {}));
})(urls || (urls = {}));

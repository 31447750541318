import { Ref, forwardRef } from 'react';
import Button, { ButtonProps } from './Button';
import Icon, { IconName } from './Icon';

interface Props extends Omit<ButtonProps, 'children' | 'ref'> {
  iconName: IconName;
}

function IconButton(
  { iconName, ...buttonProps }: Props,
  ref: Ref<HTMLAnchorElement | HTMLButtonElement>,
) {
  return (
    <Button ref={ref} className="icon-button" color="text" {...buttonProps}>
      <Icon name={iconName} />
    </Button>
  );
}

export default forwardRef(IconButton);

import {
  GOOGLE_ANALYTICS_KEY__PRD,
  GOOGLE_ANALYTICS_KEY__COM,
} from '@motional-cc/fe/keys';
import { ReactNode, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { env } from 'src/api/hosts';
import { VITE_GOOGLE_ANALYTICS_KEY } from 'src/config/env';
import { Immutable } from 'src/interface/utility';
import assertArrayExhaustsType from 'src/tools/assertArrayExhaustsType';

type EnvWithProductionAnalytics = 'prd' | 'com';
const ENVS_WITH_PRODUCTION_ANALYTICS =
  assertArrayExhaustsType<EnvWithProductionAnalytics>()(['com', 'prd']);
const PRODUCTION_ANALYTICS_KEYS: Immutable<{
  [Env in EnvWithProductionAnalytics]: string;
}> = {
  prd: GOOGLE_ANALYTICS_KEY__PRD,
  com: GOOGLE_ANALYTICS_KEY__COM,
} as const;

const analyticsKey: string | undefined =
  VITE_GOOGLE_ANALYTICS_KEY ?? (env && PRODUCTION_ANALYTICS_KEYS[env]);

const devMode: boolean =
  !!VITE_GOOGLE_ANALYTICS_KEY ||
  !ENVS_WITH_PRODUCTION_ANALYTICS.includes(env as EnvWithProductionAnalytics);

export const SHOULD_TRACK = !!analyticsKey;

function Analytics({ children }: { children: ReactNode }) {
  const location = useLocation();

  useEffect(function initGoogleAnalytics() {
    if (!analyticsKey) return;

    ReactGA.initialize(analyticsKey, {
      testMode: devMode,
    });
  }, []);

  useEffect(
    function sendPageChangeToGoogleAnalytics() {
      if (!SHOULD_TRACK) return;

      // Removing search params for now, as they’re quite the wild card
      // Update here later if we want to track location.search
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname.split('?')[0].split('#')[0],
      });
    },
    [location.pathname],
  );

  return <>{children}</>;
}

export default Analytics;

// THIS IS AN AUTOGENERATED FILE, DO NOT MODIFY DIRECTLY
/* istanbul ignore file */

const brandColors = {
  noirAmbient60: '#2f3234',
  noirAmbient80: '#3f4244',
  noirAmbient85: '#2f3234',
  noirAmbient90: '#24282a',
  noirAmbient95: '#1a1e20',
  noirAmbient98: '#131719',
  noirAmbient: '#0f1315',
  noirNeutral10: '#8894d1',
  noirNeutral40: '#5e6791',
  noirNeutral: '#4e5578',
  noirNeutral60: '#353a52',
  noirNeutral70: '#2d3145',
  lightAsphalt40: '#eaefef',
  lightAsphalt: '#d2dcdc',
  lightAsphalt60: '#bac9c9',
  lightAsphalt70: '#9db1b1',
  lightAsphalt80: '#798d8d',
  lightAsphalt90: '#5b6c6c',
  asphalt10: '#f6f6f8',
  asphalt20: '#e3e6ec',
  asphalt40: '#b4b7be',
  asphalt: '#828791',
  asphalt70: '#5b5f67',
  asphalt90: '#40444a',
  black: '#030a14',
  purple02: '#fafafd',
  purple05: '#f2f2f9',
  purple10: '#e7e6f4',
  purple20: '#c9c3f9',
  purple30: '#988bf9',
  purple40: '#8179f1',
  motionalPurple: '#5c48f6',
  purple70: '#2d2baa',
  purple90: '#111471',
  purple100: '#0e0e4f',
  lavenderGray30: '#eceef8',
  lavenderGray40: '#d1d8ef',
  lavenderGray: '#adb9e3',
  lavenderGray60: '#8395d5',
  lavenderGray70: '#5f76c9',
  electricPurple10: '#f8eefe',
  electricPurple20: '#f0ddfd',
  electricPurple30: '#daa8f9',
  electricPurple40: '#c981f8',
  electricPurple: '#b653f5',
  electricPurple70: '#9515e6',
  electricPurple90: '#6f00b6',
  hyundaiBlue10: '#caddf7',
  hyundaiBlue20: '#98bbf0',
  hyundaiBlue30: '#608ad4',
  hyundaiBlue40: '#355caa',
  hyundaiBlue: '#0a2972',
  hyundaiBlue70: '#051752',
  hyundaiBlue90: '#010b36',
  ripple: '#e0f4f9',
  seaglass: '#c9eaf1',
  seaglass40: '#61c2d6',
  seaglass50: '#27bedd',
  seaglassRipple30: '#a2dbe7',
  seaglassRipple: '#61c2d6',
  seaglassRipple60: '#27bedd',
  seaglassRipple70: '#00abce',
  seaglassRipple80: '#0092b0',
  solidAqua20: '#cdfdff',
  solidAqua30: '#8ffbff',
  solidAqua: '#00f6ff',
  solidAqua60: '#00cfd6',
  solidAqua80: '#009da2',
  vibrantRed30: '#f97a5e',
  vibrantRed: '#f86949',
  vibrantRed60: '#df5f44',
  vibrantRed70: '#c7563e',
  vibrantRed80: '#ae4c39',
  vibrantRed90: '#964334',
  accessibleRed: '#dc2e07',
  red20: '#fdd8d5',
  red30: '#f9998f',
  red40: '#f67365',
  littleRed: '#ed5645',
  red60: '#c13323',
  red70: '#ae2011',
  red80: '#6f2820',
  redOrange20: '#fde2d0',
  redOrange30: '#f8995c',
  redOrange: '#f56c16',
  redOrange60: '#e05700',
  redOrange80: '#c04a00',
  orange10: '#fdefcf',
  orange20: '#fce8ba',
  orange30: '#f9d78a',
  orange: '#f5b016',
  orange60: '#e3a009',
  orange80: '#c08500',
  yellow20: '#fdf7d0',
  yellow30: '#f9e673',
  yellow: '#f5d616',
  yellow60: '#dbc114',
  yellow80: '#c5aa06',
  beam20: '#f8ffad',
  beam: '#e2ff1a',
  beam60: '#ceec00',
  beam65: '#bcd700',
  beam70: '#adc600',
  beam80: '#84901a',
  turf20: '#cfffe4',
  turf30: '#8fffc1',
  turf: '#00ff73',
  turf60: '#00d962',
  turf80: '#00a249',
  turf90: '#147942',
  blueGreen60: '#09cc92',
  mapPrimaryLight: '#eef0f5',
  mapPrimaryDark: '#1f2529',
  trueBlack: '#000000',
  gray1: '#212121',
  gray2: '#333333',
  gray3: '#424242',
  gray4: '#656565',
  gray5: '#808080',
  gray6: '#979797',
  gray7: '#aeaeae',
  gray8: '#c7c7c7',
  gray9: '#ededed',
  gray10: '#f7f7f7',
  white: '#ffffff',
  partnerLyft1: '#ff00bf',
  partnerLyft2: '#ff73dc',
  partnerUberEats1: '#06c167',
  partnerUberEats2: '#67dfa5',
  partnerVia1: '#4aa6dd',
  partnerUber1: '#000000',
  partnerUber2: '#737373',
  partnerUber3: '#939596',
  partnerUber4: '#ffffff',
  notificationColor: '#f5f3f3',
  aptivOrange: '#f84018',
  highlite: '#e27b00',
  sun: '#ffa211',
  lightGray: '#e5e1da',
  kiwi: '#d9f28b',
  pineGreen: '#009e1e',
  darkGray: '#929d96',
  mediumTeal: '#1dbe9c',
  turquoise: '#00ac9e',
  lightTeal: '#00cfbf',
  lightSlateBlue: '#b7d1cf',
  darkTurquoise: '#006b63',
  darkSlateBlue: '#4e7c88',
  sky: '#3bc6eb',
  listItemHover: '#0e4557',
  listItemSelected: '#003f57',
  lightBackground: '#7d9098',
  lightBackground2: '#8a9aa4',
  darkBackground: '#112430',
  cornflowerBluishGray: '#748692',
  darkTeal2: '#3c6687',
  pureMaterialPrimary: '#2196f3',
  darkBackground2: '#182d3e',
  blueGray: '#3d4554',
  versionTabBackground: '#1b1f27',
  darkTeal: '#0e1626',
  darkTrueBlue: '#2e3442',
  lightTrueBlue: '#bac4f8',
  bannergrayLight: '#b5b6bb',
  darkBlue: '#354180',
  trueBlue: '#6579e2',
  rideStatus: '#000a40',
  bannergrayDark: '#41424a',
  night: '#383942',
  burntRed: '#cf3335',
} as const;

export type BrandColorName = keyof typeof brandColors;

export default brandColors;

import { VehicleSettings } from '@motional-cc/fe/interface/api/api-server';
import t from '@motional-cc/fe/tools/translate';
import { apiServerPaths } from 'src/api/api-server';
import { useApi } from 'src/api/hooks/service';
import { userApi } from 'src/api/user';
import Definition from 'src/components/common/Definition';
import DefinitionList from 'src/components/common/DefinitionList';
import IconButton from 'src/components/common/IconButton';
import LoadingErrorEmptyState from 'src/components/common/LoadingErrorEmptyState';
import { objectEntries } from 'src/tools/object/objectEntries';

interface Props {
  carId: string;
  onEditSettingsClick: () => void;
}

function ManageVehicleSettings({ carId, onEditSettingsClick }: Props) {
  const canManagePartnerVehicles = userApi.useHasScopes([
    'manage:vehicle-settings',
  ]);

  const {
    result: vehicleSummary,
    status,
    error,
  } = useApi<VehicleSettings.GetPublicBehaviorsVehicleSummariesVehicleId.ResponseBody>(
    apiServerPaths.VehicleSettings.GetPublicBehaviorsVehicleSummariesVehicleId(
      carId,
    ),
  );

  const vehicleSettingsSummaryEntries = vehicleSummary?.settings
    ? objectEntries(vehicleSummary.settings)
    : [];

  return (
    <LoadingErrorEmptyState
      result={vehicleSettingsSummaryEntries}
      status={status}
      error={error}
    >
      {(vehicleSettingsSummaryEntries) => (
        <div>
          <header className="vehicle-management__details-header">
            <hgroup className="vehicle-management__details-hgroup">
              <h4 className="vehicle-management__details-title">
                {t('vehicleRegistration.editSettings.title')}
              </h4>
              <p className="vehicle-management__details-strapline">
                {t('vehicleRegistration.editSettings.strapline')}
              </p>
            </hgroup>

            {canManagePartnerVehicles && (
              <IconButton
                className="vehicle-management__details-action"
                iconName="SquareWithPencil"
                aria-label="Edit behaviors"
                onClick={onEditSettingsClick}
              />
            )}
          </header>

          {vehicleSettingsSummaryEntries.length ? (
            <DefinitionList className="vehicle-management__settings-list">
              {vehicleSettingsSummaryEntries.map(
                ([behaviorName, setting], index) => (
                  <Definition
                    key={index}
                    title={
                      t(`vehicleSettings.setting.${behaviorName}`) ||
                      setting.name
                    }
                    definition={
                      <>
                        {String(setting.value)}
                        <span className="vehicle-management__setting-source">
                          {` (${
                            t(`vehicleSettings.source.${setting.source}`) ||
                            setting.source
                          })`}
                        </span>
                      </>
                    }
                  />
                ),
              )}
            </DefinitionList>
          ) : (
            <p>No vehicle behaviors</p>
          )}
        </div>
      )}
    </LoadingErrorEmptyState>
  );
}

export default ManageVehicleSettings;

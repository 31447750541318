import { useEffect, useState, useSyncExternalStore } from 'react';

function useMediaQuery(mediaQuery: string) {
  const [mediaMatcher, setMediaMatcher] = useState(
    window.matchMedia(mediaQuery),
  );

  useEffect(
    function updateMediaMatcher() {
      const newMediaMatcher = window.matchMedia(mediaQuery);
      setMediaMatcher(newMediaMatcher);
    },
    [mediaQuery],
  );

  return useSyncExternalStore(
    (listener) => {
      if (mediaMatcher.addEventListener) {
        mediaMatcher.addEventListener('change', listener);
      } else {
        mediaMatcher.addListener(listener);
      }

      return () => {
        if (mediaMatcher.removeEventListener) {
          mediaMatcher.removeEventListener('change', listener);
        } else {
          mediaMatcher.removeListener(listener);
        }
      };
    },
    () => window.matchMedia(mediaQuery).matches,
  );
}

export default useMediaQuery;

import clsx from 'clsx';
import { MouseEvent, ReactNode, useEffect, useRef } from 'react';
import Checkbox from 'src/components/common/Checkbox';
import ScrollIntoView from 'src/components/common/ScrollIntoView';
import { scrollIntoView } from './utils';
import './SelectListOption.scss';

type Props<Option> = {
  type?: 'checkbox' | 'radio';
  option: Option;
  renderContent?: (value: Option) => ReactNode;
  isSelected?: boolean;
  disabled?: boolean;
  isHighlighted?: boolean;

  // needed for scrolling into view correctly
  listElement?: HTMLUListElement;

  onOptionSelect?: (option: Option) => void;
  onOptionHighlight?: (option: Option) => void;
};

function SelectListOption<Option>({
  type = 'radio',
  option,
  isSelected = false,
  disabled,
  isHighlighted,
  listElement,
  renderContent = (option) => option as ReactNode,
  onOptionSelect,
  onOptionHighlight,
}: Props<Option>) {
  const liRef = useRef<HTMLLIElement>(null);

  const handleMouseDown = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (disabled) return;

    onOptionSelect?.(option);
  };

  const handleHighlight = () => {
    onOptionHighlight?.(option);
  };

  useEffect(
    function scrollHighlightedIntoView() {
      if (isHighlighted && listElement && liRef.current) {
        scrollIntoView(listElement, liRef.current);
      }
    },
    [isHighlighted, listElement],
  );

  const renderedContent = renderContent(option);
  const content =
    typeof renderedContent === 'string' ? (
      <span className="select-list-option__value">{renderedContent}</span>
    ) : (
      renderedContent
    );

  const optionInternals =
    type === 'checkbox' ? (
      <Checkbox
        className="select-list-option__option select-list-option__option--multi"
        label={content}
        onFocus={handleHighlight}
        checked={isSelected}
        disabled={!!disabled}
      />
    ) : (
      <span className="select-list-option__option select-list-option__option--single">
        {content}
      </span>
    );

  return (
    <ScrollIntoView when={isSelected || isHighlighted}>
      <li
        ref={liRef}
        role="option"
        aria-selected={isSelected}
        aria-disabled={disabled}
        onMouseDown={handleMouseDown}
        onMouseEnter={disabled ? undefined : handleHighlight}
        className={clsx([
          'select-list-option',
          {
            'select-list-option--selected': isSelected,
            'select-list-option--disabled': disabled,
            'select-list-option--highlighted': isHighlighted,
          },
        ])}
      >
        {optionInternals}
      </li>
    </ScrollIntoView>
  );
}

export default SelectListOption;

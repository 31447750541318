import { FloatingPortal } from '@floating-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import transition from 'src/tools/transition';
import Message from './Message';
import { useMessages } from './messages-context';
import './Messages.scss';

function Messages() {
  const { messages, dismissMessage } = useMessages();
  const currentMessage = messages[0];

  return (
    <FloatingPortal>
      <div className="messages-container">
        <AnimatePresence>
          {currentMessage && (
            <motion.div
              key={currentMessage.id}
              className="messages-container__message-wrapper"
              layoutId={currentMessage.id}
              {...transition({
                absolute: true,
                entrance: {
                  y: -50,
                },
                exit: {
                  y: 50,
                },
              })}
            >
              <Message
                className="messages-container__message"
                message={currentMessage}
                onDismiss={dismissMessage}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </FloatingPortal>
  );
}

export default Messages;

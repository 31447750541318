import { Model as CarModelName } from '@motional-cc/fe/interface/api/registrar';
import clsx from 'clsx';
import { ChangeEvent, useId } from 'react';
import carProfiles from 'src/static/img/car-profiles';
import './VehicleModelRadio.scss';

type Props = {
  model: CarModelName;
  checked: boolean;
  disabled?: boolean;
  isStatic?: boolean;
  autoFocus?: boolean;
  onChange: (checked: boolean) => void;
};

function VehicleModelRadio({
  model,
  checked,
  disabled: disabledProps,
  isStatic,
  autoFocus,
  onChange,
}: Props) {
  const id = useId();
  const disabled = !!(disabledProps || isStatic);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <div
      className={clsx([
        'vehicle-model-radio',
        { 'vehicle-model-radio--is-disabled': disabled },
        { 'vehicle-model-radio--is-static': isStatic },
        { 'vehicle-model-radio--is-checked': checked },
      ])}
    >
      <input
        className="vehicle-model-radio__input"
        id={id}
        name="vehicle-model"
        onChange={handleInputChange}
        checked={checked}
        type="radio"
        disabled={disabled}
        autoFocus={autoFocus}
      />
      <label className="vehicle-model-radio__label" htmlFor={id}>
        <img
          className="vehicle-model-radio__car-img"
          src={carProfiles[model]}
          role="presentation"
          alt={model}
        />
        <span className="vehicle-model-radio__label-text">{model}</span>
      </label>
    </div>
  );
}

export default VehicleModelRadio;

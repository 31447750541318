import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  title: string;
  children: ReactNode;
}

function PageTitle({ title, children }: Props) {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      {children}
    </>
  );
}

export default PageTitle;

export var urls;
(function (urls) {
    let Locations;
    (function (Locations_1) {
        Locations_1.Locations = (region) => `/locations/${region}`;
    })(Locations = urls.Locations || (urls.Locations = {}));
    let Count;
    (function (Count_1) {
        Count_1.Count = (index) => `/count/${index}`;
    })(Count = urls.Count || (urls.Count = {}));
    let Search;
    (function (Search) {
        Search.SearchByIndex = (index) => `/search/${index}`;
        Search.LuceneUriSearch = () => `/search`;
    })(Search = urls.Search || (urls.Search = {}));
    let Health;
    (function (Health) {
        Health.HealthList = () => `/health`;
    })(Health = urls.Health || (urls.Health = {}));
    let ServiceInfo;
    (function (ServiceInfo) {
        ServiceInfo.ServiceInfoList = () => `/service_info`;
    })(ServiceInfo = urls.ServiceInfo || (urls.ServiceInfo = {}));
    let Register;
    (function (Register) {
        Register.RegisterOperator = (carcode) => `/register/${carcode}/operator`;
    })(Register = urls.Register || (urls.Register = {}));
    let Vehicles;
    (function (Vehicles) {
        Vehicles.UpdateVehiclePartners = (vehicleId) => `/vehicles/${vehicleId}/partner`;
        Vehicles.SetDispatchMode = (vehicleId) => `/vehicles/${vehicleId}/dispatch-mode`;
        Vehicles.DeleteVehicleFaults = (vehicleId) => `/vehicles/${vehicleId}/faults`;
    })(Vehicles = urls.Vehicles || (urls.Vehicles = {}));
    let Partners;
    (function (Partners) {
        Partners.GetVehiclePartners = () => `/partners`;
        Partners.GetPartnerAllowedPreferences = (partner) => `/partners/${partner}/job-preferences`;
    })(Partners = urls.Partners || (urls.Partners = {}));
    let Operators;
    (function (Operators) {
        Operators.GetVehicleByOperatorId = (userId) => `/operators/${userId}/vehicle`;
    })(Operators = urls.Operators || (urls.Operators = {}));
})(urls || (urls = {}));

import t, { Interpolations } from '@motional-cc/fe/tools/translate';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { getStatusDefaultMessage } from 'src/api/service';
import EmptyState, {
  EMPTY_STATE_DESCRIPTION_CLASS,
} from 'src/components/common/EmptyState';
import {
  ApiError,
  DataLoadStatus,
  isIncompleteStatus,
} from 'src/interface/command-center';
import { isNotNullish } from 'src/tools/types';
import FullWidthLoader from './FullWidthLoader';
import './LoadingErrorEmptyState.scss';

const DEFAULT_ERROR_MESSAGE = 'An error occurred';

const defaultRenderErrorTitle = (error?: ApiError) => {
  if (!error) return DEFAULT_ERROR_MESSAGE;

  return (
    (error.errors?.length && error.message) ||
    (error.status && getStatusDefaultMessage(error.status)) ||
    DEFAULT_ERROR_MESSAGE
  );
};

const defaultRenderErrorDetails = (error: ApiError) => {
  if (error.errors?.length) {
    return (
      <>
        {error.errors.map((error) =>
          error.loc.map((loc) => (
            <p
              key={`${loc}--${error.type}`}
              className={EMPTY_STATE_DESCRIPTION_CLASS}
            >
              {t(`fieldErrors.${error.type}`, { loc }) ||
                (error.msg
                  ? `${loc}: ${error.msg}`
                  : `Something went wrong with field: ${loc}`)}
            </p>
          )),
        )}
      </>
    );
  } else {
    return `${getStatusDefaultMessage(error.status)}${
      error.message ? `: ${error.message}` : ''
    }`;
  }
};

interface Props<Result> {
  className?: string;
  children?: (result: Result) => ReactNode;
  status: DataLoadStatus;
  result?: Result | null | undefined;
  error?: ApiError | null | undefined;
  errorPayload?: Interpolations;
  renderErrorTitle?: ((error: ApiError) => string) | undefined;
  renderErrorDetails?: ((error: ApiError) => ReactNode) | undefined;
  loadingTitle?: string;
  renderLoadingState?: (() => ReactNode) | undefined;
  emptyTitle?: string;
  emptyDetails?: string | undefined;
  hideEmptyState?: boolean;
}

function LoadingErrorEmptyState<Result>({
  className,
  children,
  status,
  result,
  error,
  errorPayload,
  renderErrorTitle = defaultRenderErrorTitle,
  renderErrorDetails = defaultRenderErrorDetails,
  emptyTitle,
  emptyDetails,
  hideEmptyState,
  loadingTitle,
  renderLoadingState,
}: Props<Result>) {
  let errorNode = null;

  if (status === 'error') {
    if (error) {
      errorNode = (
        <EmptyState
          className="loading-error-empty-state__empty-state"
          title={renderErrorTitle(error)}
          description={renderErrorDetails({
            ...error,
            // I don’t know why TypeScript tells me to add this line
            name: error.name,
            message:
              (error.code && t(`errorCodes.${error.code}`, errorPayload)) ||
              error.message,
          })}
        />
      );
    } else {
      errorNode = (
        <EmptyState
          className="loading-error-empty-state__empty-state"
          title={DEFAULT_ERROR_MESSAGE}
        />
      );
    }
  }

  return errorNode ? (
    errorNode
  ) : isIncompleteStatus(status) ? (
    renderLoadingState?.() ||
    (loadingTitle ? (
      <EmptyState
        title={loadingTitle}
        description={
          <FullWidthLoader
            className={clsx([className, 'loading-error-empty-state__loader'])}
          />
        }
      />
    ) : (
      <FullWidthLoader
        className={clsx([className, 'loading-error-empty-state__loader'])}
      />
    ))
  ) : !children ? null : isNotNullish(result) &&
    (!Array.isArray(result) || result.length > 0) ? (
    <>{children?.(result)}</>
  ) : !children || hideEmptyState ? null : (
    <EmptyState
      className={clsx([className, 'loading-error-empty-state__empty-state'])}
      title={emptyTitle || 'No data found'}
      description={emptyDetails}
    />
  );
}

export default LoadingErrorEmptyState;

// This is because CSS vh and vw don’t acount for browser chrome in some browsers
// (especially mobile)

const setWindowSizeCustomProperties = () => {
  document.documentElement.style.setProperty(
    '--window-height',
    `${globalThis.innerHeight}px`,
  );
  document.documentElement.style.setProperty(
    '--window-width',
    `${globalThis.innerWidth}px`,
  );
};

setWindowSizeCustomProperties();

export const updateWindowSizeStyles = () => {
  globalThis.addEventListener('resize', () => {
    setWindowSizeCustomProperties();
  });
};

import t from '@motional-cc/fe/tools/translate';
import {
  forwardRef,
  HTMLInputTypeAttribute,
  RefObject,
  useMemo,
  useState,
} from 'react';
import InputField, { InputProps } from './InputField';

type InputTypesWithPatternAttribute = Extract<
  HTMLInputTypeAttribute,
  'text' | 'tel' | 'email' | 'url' | 'password' | 'search'
>;

export type TextFieldProps = Omit<InputProps, 'type'> & {
  type?: InputTypesWithPatternAttribute;
};

function TextField(
  {
    showErrorMessage: propsShowErrorMessage,
    type = 'text',
    ...inputProps
  }: TextFieldProps,
  ref: RefObject<HTMLInputElement>,
) {
  const [hasBlurred, setHasBlurred] = useState(false);
  const errorMessage = useMemo(() => {
    if (inputProps.errorMessage) {
      return inputProps.errorMessage;
    }

    const value = String(inputProps.value ?? '');
    if (inputProps.required && !value) {
      return t('fieldValidation.missingRequiredValue', {
        label: inputProps.label,
      });
    }

    if (!!value && inputProps.pattern && !inputProps.pattern.test(value)) {
      return t('fieldValidation.patternMismatch', {
        pattern: String(inputProps.pattern),
      });
    }

    return undefined;
  }, [
    inputProps.errorMessage,
    inputProps.label,
    inputProps.pattern,
    inputProps.required,
    inputProps.value,
  ]);

  const handleBlur = (newValue: string) => {
    setHasBlurred(true);
    inputProps.onBlur?.(newValue);
  };

  return (
    <InputField
      {...inputProps}
      ref={ref}
      errorMessage={errorMessage}
      showErrorMessage={propsShowErrorMessage ?? hasBlurred}
      onBlur={handleBlur}
      type={type}
    />
  );
}

export default forwardRef(TextField);

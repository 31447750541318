import { captureException } from '@sentry/browser';
import { FunctionComponent, lazy } from 'react';
import { ApiError } from 'src/interface/command-center';
import { updateErrorMessage } from './object/updateErrorMessage';

interface Props {
  Fallback?: FunctionComponent<{ error: Error | ApiError }>;
  maxAttempts?: number;
  throwAfterAttempts?: boolean;
}

export const lazyWithRetry = <ImportedComponent extends FunctionComponent>(
  loader: () => Promise<{ default: ImportedComponent }>,
  {
    Fallback = ({ error }) => `Failed to import component: ${error.message}`,
    maxAttempts = 5,
    throwAfterAttempts,
  }: Props = {},
) =>
  lazy<ImportedComponent>(async () => {
    let importingModule: { default: ImportedComponent } | undefined;
    let attemptCount = 0;
    let error: ApiError | Error = new Error('No error');

    while (!importingModule) {
      try {
        importingModule = await loader();
      } catch (_error: unknown) {
        attemptCount += 1;

        if (attemptCount >= maxAttempts) {
          if (throwAfterAttempts) {
            throw _error;
          }
          error = updateErrorMessage<ApiError>(
            _error,
            (message) =>
              `Failed to import even after ${maxAttempts} retries: ${message}. Loader: ${String(
                loader,
              )}`,
          );
          captureException(error);
          break;
        }
      }
    }

    if (importingModule) {
      return importingModule;
    } else {
      const FallbackComponent = () => {
        return <Fallback error={error} />;
      };
      return { default: FallbackComponent as unknown as ImportedComponent };
    }
  });

import t from '@motional-cc/fe/tools/translate';
import clsx from 'clsx';
import { useState } from 'react';
import Icon from 'src/components/common/Icon';
import { Message as MessageType } from 'src/components/Messages/messages-context';
import { inMilliseconds } from 'src/tools/date-time/inMilliseconds';
import { useUserIsActive } from 'src/tools/hooks/userIsActive';
import { useTimeout } from 'src/tools/hooks/useTimeout';
import './Message.scss';

// the real max timout is 2**31, but going over makes the value 1
const INFINITE_TIMEOUT = 2 ** 30;
const DISMISS_TIMEOUT = inMilliseconds(10, 'seconds');
const INACTIVE_TIMEOUT = inMilliseconds(15, 'seconds');

const iconMessageMap = {
  success: <Icon name="CircleWithTick" className="the-message__icon" />,
  warning: (
    <Icon name="TriangleWithExclamation" className="the-message__icon" />
  ),
  error: <Icon name="DiamondWithExclamation" className="the-message__icon" />,
  info: <Icon name="CircleWithLetterI" className="the-message__icon" />,
};

type Props = {
  message: MessageType;
  onDismiss?: (messageId: string) => void;
  className?: string;
};

function Message({ message, onDismiss, className }: Props) {
  const [autoDismissReady, setAutoDismissReady] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const userIsactive = useUserIsActive(INACTIVE_TIMEOUT);

  useTimeout(
    () => {
      if (isHovered) {
        setAutoDismissReady(true);
      } else {
        onDismiss?.(message.id);
      }
    },

    userIsactive ? DISMISS_TIMEOUT : INFINITE_TIMEOUT,
  );

  const handleDismissClick = () => {
    onDismiss?.(message.id);
  };

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleUnhover = () => {
    setIsHovered(false);
    if (autoDismissReady) {
      onDismiss?.(message.id);
    }
  };

  return (
    <div
      role="status"
      onMouseEnter={handleHover}
      onMouseLeave={handleUnhover}
      className={clsx([
        'the-message',
        className,
        { [`the-message--${message.type}`]: message.type },
      ])}
    >
      <div className="the-message__mark">{iconMessageMap[message.type]}</div>

      {(message.title || message.description) && (
        <hgroup className="the-message__content">
          {message.title && (
            <h3 className="the-message__title">{message.title}</h3>
          )}

          {!message.description ? null : Array.isArray(message.description) ? (
            message.description.map((description, index) => (
              <p key={index} className="the-message__description">
                {description}
              </p>
            ))
          ) : (
            <p className="the-message__description">{message.description}</p>
          )}
        </hgroup>
      )}
      {onDismiss && (
        <button
          type="button"
          className="the-message__dismiss"
          onClick={handleDismissClick}
          aria-label={t('messages.dismiss')}
        >
          <Icon name="MultiplyFat" />
        </button>
      )}
    </div>
  );
}

export default Message;

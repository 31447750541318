import t from '@motional-cc/fe/tools/translate';
import map from 'lodash/map';
import { useMemo } from 'react';
import { userApi } from 'src/api/user';
import CarModelCell from 'src/components/common/Table/CarModelCell';
import CarPlatformCell from 'src/components/common/Table/CarPlatformCell';
import { ColumnDefinition } from 'src/components/common/Table/interface';
import TextCell from 'src/components/common/Table/TextCell';
import TranslatedCell from 'src/components/common/Table/TranslatedCell';
import VehiclePartnerNamesCell from 'src/components/common/Table/VehiclePartnerNamesCell';
import VehiclePartnerPreferencesCell from 'src/components/common/Table/VehiclePartnerPreferencesCell';
import VehiclePartnerVehicleIdsCell from 'src/components/common/Table/VehiclePartnerVehicleIdsCell';
import VehicleSettingsCell from 'src/components/common/Table/VehicleSettingsCell';
import TableColumnSelect from 'src/components/VehicleConfiguration/TableColumnSelect';
import { objectEntries } from 'src/tools/object/objectEntries';

export type VehicleRegistrationColumnKey =
  | 'partnerNames'
  | 'vin'
  | 'carId'
  | 'carName'
  | 'model'
  | 'licensePlate'
  | 'region'
  | 'partnerIds'
  | 'settings'
  | 'partnerPreferences'
  | 'platform';

const ALL_COLUMNS: {
  [key in VehicleRegistrationColumnKey]: ColumnDefinition & {
    sortable?: boolean;
  };
} = {
  vin: {
    label: t('vehicleRegistration.tableHeaders.vin'),
    valuePath: 'vin',
    CellComponent: TextCell,
    sortable: true,
    allowWordBreak: true,
    isLockedLeft: true,
    minWidth: 120,
  },
  model: {
    label: t('vehicleRegistration.tableHeaders.model'),
    valuePath: 'model',
    CellComponent: CarModelCell,
    sortable: true,
  },
  carId: {
    label: t('vehicleRegistration.tableHeaders.carId'),
    valuePath: 'carId',
    CellComponent: TextCell,
    sortable: true,
    allowWordBreak: true,
    minWidth: 120,
  },
  carName: {
    label: t('vehicleRegistration.tableHeaders.carName'),
    valuePath: 'carName',
    CellComponent: TextCell,
    sortable: true,
    allowWordBreak: true,
    minWidth: 120,
  },
  region: {
    label: t('vehicleRegistration.tableHeaders.region'),
    valuePath: 'region',
    CellComponent: TranslatedCell,
    sortable: true,
    translatePrefix: 'region.',
  },
  platform: {
    label: t('vehicleRegistration.tableHeaders.platform'),
    valuePath: 'carId',
    CellComponent: CarPlatformCell,
  },
  licensePlate: {
    label: t('vehicleRegistration.tableHeaders.licensePlate'),
    valuePath: 'licensePlate',
    CellComponent: TextCell,
  },
  partnerNames: {
    label: t('vehicleRegistration.tableHeaders.partners'),
    valuePath: '',
    CellComponent: VehiclePartnerNamesCell,
  },
  partnerPreferences: {
    label: t('vehicleRegistration.tableHeaders.partnerPreferences'),
    valuePath: '',
    CellComponent: VehiclePartnerPreferencesCell,
  },
  partnerIds: {
    label: t('vehicleRegistration.tableHeaders.partnerIds'),
    valuePath: '',
    CellComponent: VehiclePartnerVehicleIdsCell,
  },
  settings: {
    label: t('vehicleRegistration.tableHeaders.settings'),
    valuePath: 'carId',
    CellComponent: VehicleSettingsCell,
  },
} as const;

const COLUMNS = objectEntries(ALL_COLUMNS).map(([key, column]) => ({
  ...column,
  key,
}));

export const DEFAULT_PICKED_COLUMNS: VehicleRegistrationColumnKey[] = [
  'vin',
  'carName',
  'model',
  'region',
  'partnerNames',
  'partnerPreferences',
  'settings',
];

const COLUMN_KEYS: Readonly<VehicleRegistrationColumnKey[]> = map(
  COLUMNS,
  'key',
);

export const useAvailableColumns = () => {
  const hasReadManifestsScopes = userApi.useHasScopes(['read:manifests']);

  return useMemo(
    () =>
      hasReadManifestsScopes
        ? [...COLUMNS]
        : COLUMNS.filter((column) => column.key !== 'platform'),
    [hasReadManifestsScopes],
  );
};

export const useAvailableColumnKeys = () => {
  const hasReadManifestsScopes = userApi.useHasScopes(['read:manifests']);

  return useMemo(
    () =>
      hasReadManifestsScopes
        ? [...COLUMN_KEYS]
        : COLUMN_KEYS.filter((columnName) => columnName !== 'platform'),
    [hasReadManifestsScopes],
  );
};

const REQUIRED_COLUMNS: VehicleRegistrationColumnKey[] = ['vin'];

function VehicleRegistrationColumnPicker() {
  const availableColumnKeys = useAvailableColumnKeys();

  return (
    <TableColumnSelect
      settingName="vehicle-registration"
      columnNames={availableColumnKeys}
      defaultSelected={DEFAULT_PICKED_COLUMNS}
      disabledColumns={REQUIRED_COLUMNS}
      renderLabel={(columnName) => ALL_COLUMNS[columnName]?.label}
    />
  );
}

export default VehicleRegistrationColumnPicker;

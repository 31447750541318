import {
  FloatingFocusManager,
  FloatingPortal,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import clsx from 'clsx';
import { ReactNode } from 'react';
import PanelHeaderWithClose, {
  Props as PanelHeaderWithCloseProps,
} from 'src/components/common/PanelHeaderWithClose';
import './Modal.scss';

export type Props = PanelHeaderWithCloseProps & {
  isOpen?: boolean;
  children?: ReactNode | (() => ReactNode);
};

function Modal({ children, isOpen, ...headerProps }: Props) {
  const { context, refs } = useFloating();
  const { getFloatingProps } = useInteractions();

  const handleCancelClick = () => {
    headerProps.onClose?.();
  };

  return (
    <FloatingPortal>
      {isOpen && (
        <>
          <button
            className={clsx(['the-modal-underlay', 'theme--inverted'])}
            aria-label="Click to dismiss modal"
            onClick={handleCancelClick}
            type="button"
          />

          <FloatingFocusManager context={context}>
            <div
              {...getFloatingProps({
                ref: refs.floating,
                className: 'the-modal',
              })}
            >
              <PanelHeaderWithClose {...headerProps} />
              <div className="the-modal__content">
                {typeof children === 'function' ? children() : children}
              </div>
            </div>
          </FloatingFocusManager>
        </>
      )}
    </FloatingPortal>
  );
}

export default Modal;

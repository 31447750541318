import { useState } from 'react';
import { armadaApi } from 'src/api/armada';
import EmptyState from 'src/components/common/EmptyState';
import EditVehicle from 'src/components/VehicleRegistration/EditVehicle';
import EditVehicleSettings from './EditVehicleSettings';
import ManageVehiclePartners, { Partner } from './ManageVehiclePartners';
import ManageVehicleSettings from './ManageVehicleSettings';
import PartnerVehicleForm from './PartnerVehicleForm';
import ViewVehicle from './ViewVehicle';
import './VehicleManagement.scss';

const ROOT_CLASS_NAME = 'vehicle-management';

export type View =
  | 'viewVehicle'
  | 'editVehicle'
  | 'editPartnerVehicle'
  | 'editSettings';

const TIME_TO_PERSIST_DATA = 1000;

interface Props {
  carId?: string;
  vehicleVin?: string;
  view: View;
  onChangeView: (newView: View) => void;
  onClose: () => void;
}

function VehicleManagement({
  carId,
  vehicleVin,
  view,
  onChangeView,
  onClose,
}: Props) {
  const [editingPartner, setEditingPartner] = useState<Partner | undefined>();

  const { refetch: refetchVehicle } = armadaApi.useVehicle(carId);

  if (!carId && typeof vehicleVin !== 'string') {
    return (
      <div className={ROOT_CLASS_NAME}>
        <EmptyState title="Vehicle wasn’t specified" />
      </div>
    );
  }

  const handleEditPartnerClick = (partner: Partner | undefined) => {
    setEditingPartner(partner);
    onChangeView('editPartnerVehicle');
  };
  const handleEditPartnerClose = () => {
    onChangeView('viewVehicle');
  };
  const handleEditPartnerSuccess = () => {
    setTimeout(() => {
      refetchVehicle();
    }, TIME_TO_PERSIST_DATA);
    onChangeView('viewVehicle');
  };

  const handleEditVehicleClick = () => {
    onChangeView('editVehicle');
  };
  const handleEditVehicleClose = () => {
    onChangeView('viewVehicle');
  };
  const handleVehicleDeletion = () => {
    onClose();
  };

  const handleChangePartnersSuccess = () => {
    refetchVehicle();
  };

  const handleEditSettingsClick = () => {
    onChangeView('editSettings');
  };
  const handleEditSettingsSuccess = () => {
    onChangeView('viewVehicle');
  };

  return (
    <div className={ROOT_CLASS_NAME}>
      {
        {
          viewVehicle: (
            <div className="vehicle-management__vehicle-details">
              {carId && (
                <ViewVehicle
                  carId={carId}
                  onEditVehicleClick={handleEditVehicleClick}
                />
              )}

              <ManageVehiclePartners
                carId={carId}
                carVin={vehicleVin}
                onEditPartnerClick={handleEditPartnerClick}
                onChangeSuccess={handleChangePartnersSuccess}
              />

              {carId && (
                <ManageVehicleSettings
                  carId={carId}
                  onEditSettingsClick={handleEditSettingsClick}
                />
              )}
            </div>
          ),
          editVehicle: (
            <EditVehicle
              carId={carId}
              onCancelClick={handleEditVehicleClose}
              onSaveSuccess={handleEditVehicleClose}
              onDeleteSuccess={handleVehicleDeletion}
            />
          ),
          editPartnerVehicle: editingPartner ? (
            <PartnerVehicleForm
              vin={vehicleVin}
              partner={editingPartner.name}
              onCancelClick={handleEditPartnerClose}
              onSaveSuccess={handleEditPartnerSuccess}
              vehicleId={
                editingPartner.carId || editingPartner.partner_vehicle_id
              }
            />
          ) : (
            <EmptyState
              title="No partner selected"
              description="We’ve mixed something up. Please close this modal and try again."
            />
          ),
          editSettings: carId ? (
            <EditVehicleSettings
              carId={carId}
              onEditSuccess={handleEditSettingsSuccess}
            />
          ) : (
            <EmptyState title="Selected vehicle cannot have settings" />
          ),
        }[view]
      }
    </div>
  );
}

export default VehicleManagement;

export var urls;
(function (urls) {
    let Vehicles;
    (function (Vehicles) {
        Vehicles.GetVehicles = (vehicleId) => `/vehicles/${vehicleId}`;
        Vehicles.PutVehicles = (vehicleId) => `/vehicles/${vehicleId}`;
    })(Vehicles = urls.Vehicles || (urls.Vehicles = {}));
    let VehicleConfig;
    (function (VehicleConfig) {
        VehicleConfig.GetVehicleConfig = () => `/vehicle-config`;
        VehicleConfig.PutVehicleConfigJira = (vehicleId) => `/vehicle-config/jira/${vehicleId}`;
        VehicleConfig.PostVehicleConfigCheck = (vehicleId) => `/vehicle-config/${vehicleId}/config-check`;
        VehicleConfig.GetVehicleConfigCheckById = (resultId) => `/vehicle-config/config-check/${resultId}`;
    })(VehicleConfig = urls.VehicleConfig || (urls.VehicleConfig = {}));
    let VehicleConfigColumns;
    (function (VehicleConfigColumns) {
        VehicleConfigColumns.GetVehicleConfigColumns = () => `/vehicle-config-columns`;
    })(VehicleConfigColumns = urls.VehicleConfigColumns || (urls.VehicleConfigColumns = {}));
    let VehicleConfigVersions;
    (function (VehicleConfigVersions) {
        VehicleConfigVersions.GetComponentVersions = (componentName) => `/vehicle-config-versions/components/${componentName}`;
        VehicleConfigVersions.GetVehicleConfigComponentVersions = (componentName) => `/vehicle-config-versions/component/${componentName}`;
    })(VehicleConfigVersions = urls.VehicleConfigVersions || (urls.VehicleConfigVersions = {}));
    let VehicleConfigSources;
    (function (VehicleConfigSources) {
        VehicleConfigSources.GetVehicleConfigSourceVehicleId = (vehicleId) => `/vehicle-config-sources/vehicles/${vehicleId}`;
        VehicleConfigSources.GetVehicleConfigSourceTicketId = (ticketId) => `/vehicle-config-sources/sources/${ticketId}`;
    })(VehicleConfigSources = urls.VehicleConfigSources || (urls.VehicleConfigSources = {}));
    let V2;
    (function (V2) {
        V2.GetDtValidation = (ticketId) => `/v2/dt/${ticketId}/validate`;
        V2.GetLatestOneTouchCycle = (vehicleId) => `/v2/one-touch/vehicles/${vehicleId}`;
        V2.GetOneTouchCycle = (cycleId) => `/v2/one-touch/cycles/${cycleId}`;
        V2.GetOneTouchStepLog = (cycleId, stepId) => `/v2/one-touch/cycles/${cycleId}/steps/${stepId}/log`;
        V2.ServiceInfoList = () => `/v2/service_info`;
        V2.PostBundleV2 = () => `/v2/bundles`;
        V2.GetBundlesV2 = () => `/v2/bundles`;
        V2.GetBundleV2 = (bundleName) => `/v2/bundles/${bundleName}`;
        V2.DeleteBundleV2 = (bundleName) => `/v2/bundles/${bundleName}`;
        V2.PutVehicleV2 = (vehicleId) => `/v2/vehicles/${vehicleId}`;
        V2.PostCacheV2 = () => `/v2/cache`;
    })(V2 = urls.V2 || (urls.V2 = {}));
    let Bundles;
    (function (Bundles) {
        Bundles.GetBundles = () => `/bundles`;
        Bundles.PostBundles = () => `/bundles`;
        Bundles.GetBundleInfo = (bundle) => `/bundles/${bundle}`;
        Bundles.PutBundles = (bundle) => `/bundles/${bundle}`;
    })(Bundles = urls.Bundles || (urls.Bundles = {}));
    let BundleOptions;
    (function (BundleOptions) {
        BundleOptions.GetBundleOptionPlatforms = () => `/bundle-options/platforms`;
        BundleOptions.GetBundleOptionStacks = () => `/bundle-options/stacks`;
        BundleOptions.GetBundleServices = (stack) => `/bundle-options/stacks/${stack}/fields`;
        BundleOptions.GetMapsConfigs = () => `/bundle-options/services/maps/configs`;
    })(BundleOptions = urls.BundleOptions || (urls.BundleOptions = {}));
    let History;
    (function (History) {
        History.GetHistory = () => `/history`;
    })(History = urls.History || (urls.History = {}));
    let Ota;
    (function (Ota) {
        Ota.GetManifestEngine = (versionNumber, vehicleId) => `/ota/manifest/engine/${versionNumber}/${vehicleId}`;
        Ota.PutReport = (vehicleId) => `/ota/manifest/${vehicleId}/report`;
        Ota.GetCredentials = (env) => `/ota/credentials/${env}`;
        Ota.GetDrivelogsCredentials = () => `/ota/drivelogs/credentials`;
        Ota.GetScript = (vehicleId) => `/ota/run_script/${vehicleId}`;
    })(Ota = urls.Ota || (urls.Ota = {}));
    let Health;
    (function (Health) {
        Health.HealthList = () => `/health`;
    })(Health = urls.Health || (urls.Health = {}));
    let ServiceInfo;
    (function (ServiceInfo) {
        ServiceInfo.ServiceInfoList = () => `/service_info`;
    })(ServiceInfo = urls.ServiceInfo || (urls.ServiceInfo = {}));
    let Os;
    (function (Os) {
        Os.GetOs = () => `/os`;
    })(Os = urls.Os || (urls.Os = {}));
    let Component;
    (function (Component) {
        Component.GetComponentLabel = (componentName, componentVersion) => `/component/${componentName}/version/${componentVersion}/labels`;
        Component.PostComponentLabel = (componentName, componentVersion) => `/component/${componentName}/version/${componentVersion}/labels`;
        Component.DeleteComponentLabel = (componentName, componentVersion) => `/component/${componentName}/version/${componentVersion}/labels`;
    })(Component = urls.Component || (urls.Component = {}));
    let LabelledComponents;
    (function (LabelledComponents) {
        LabelledComponents.GetLabelledComponents = () => `/labelled-components`;
    })(LabelledComponents = urls.LabelledComponents || (urls.LabelledComponents = {}));
    let Labels;
    (function (Labels) {
        Labels.GetLabels = () => `/labels`;
    })(Labels = urls.Labels || (urls.Labels = {}));
})(urls || (urls = {}));

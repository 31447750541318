interface Props {
  x?: number;
  y?: number;
  scaleX?: number;
  scaleY?: number;
  absolute?: boolean;
  duration?: number;

  entrance?: {
    x?: number;
    y?: number;
    scaleX?: number;
    scaleY?: number;
    absolute?: boolean;
    duration?: number;
  };

  exit?: {
    x?: number;
    y?: number;
    scaleX?: number;
    scaleY?: number;
    absolute?: boolean;
    duration?: number;
  };
}

type Transition_Ease = [0.25, 0.1, 0.25, 1];
type SlideAndFadeAnimationPart = {
  opacity: 0 | 1;
  x: number;
  y: number;
  scaleX: number;
  scaleY: number;
  position?: 'absolute';
  transition: {
    ease: Transition_Ease;
    duration: number;
  };
};

type SlideAndFadeAnimation = {
  initial: Omit<SlideAndFadeAnimationPart, 'transition'>;
  animate: SlideAndFadeAnimationPart;
  exit: SlideAndFadeAnimationPart;
};

let PREFERRED_DURATION: undefined | 0;

const mediaMatcher = window.matchMedia('(prefers-reduced-motion: reduce)');
const mediaMatcherListener = ({ matches }: { matches: boolean }) => {
  PREFERRED_DURATION = matches ? 0 : undefined;
};
mediaMatcherListener({ matches: mediaMatcher.matches });

// TODO: remove the addListener fallback when Safari 15 is released
/* istanbul ignore else */
if (mediaMatcher.addEventListener) {
  mediaMatcher.addEventListener('change', mediaMatcherListener);
} else {
  mediaMatcher.addListener(mediaMatcherListener);
}

export default function transition({
  x = 0,
  y = 0,
  scaleX = 1,
  scaleY = 1,
  absolute = false,
  duration = 200,
  entrance = {},
  exit = {},
}: Props): SlideAndFadeAnimation {
  return {
    initial: {
      opacity: 0,
      x: entrance.x ?? x,
      y: entrance.y ?? y,
      scaleX: entrance.scaleX ?? scaleX,
      scaleY: entrance.scaleY ?? scaleY,
      ...(entrance.absolute ?? absolute ? { position: 'absolute' } : {}),
    },
    animate: {
      opacity: 1,
      x: 0,
      y: 0,
      scaleX: 1,
      scaleY: 1,
      transition: {
        ease: [0.25, 0.1, 0.25, 1],
        duration: PREFERRED_DURATION ?? (entrance.duration ?? duration) / 1000,
      },
    },
    exit: {
      opacity: 0,
      x: exit.x ?? x,
      y: exit.y ?? y,
      scaleX: exit.scaleX ?? scaleX,
      scaleY: exit.scaleY ?? scaleY,
      transition: {
        ease: [0.25, 0.1, 0.25, 1],
        duration: PREFERRED_DURATION ?? (exit.duration ?? duration) / 1000,
      },
      ...(exit.absolute ?? absolute ? { position: 'absolute' } : {}),
    },
  };
}

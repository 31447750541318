import { Models, ModernModel } from '@motional-cc/fe/interface/api/nursery';
import {
  BundleOptions,
  Platform as PlatformName,
} from '@motional-cc/fe/interface/api/registrar';
import find from 'lodash/find';
import { useEffect, useMemo } from 'react';
import { useApi } from 'src/api/hooks/service';
import { nurseryPaths } from 'src/api/nursery';
import { registrarPaths } from 'src/api/registrar';
import {
  CommonProps,
  SecondaryActionProps,
} from 'src/components/common/Select/interface';
import { AllOrNoneOf } from 'src/interface/utility';
import Select from './Select';
import './SelectEntity.scss';

type Props = {
  value?: PlatformName;
  onSelect: (newValue?: PlatformName) => void;
  model?: ModernModel;
  disabled?: boolean;
  label?: string;
  className?: string;
} & Omit<
  CommonProps<Platform>,
  'options' | 'onScrollToBottom' | 'renderSelectedOption' | 'label'
> &
  AllOrNoneOf<SecondaryActionProps>;

type Platform =
  BundleOptions.GetBundleOptionPlatforms.ResponseBody['platforms'][0];

function SelectPlatform({
  value: propsValue,
  onSelect,
  model,
  required,
  disabled,
  label = 'Vehicle platform',
  className,
  ...selectProps
}: Props) {
  const { result: _allPlatforms, isFetching: isFetchingAllPlatforms } =
    useApi<BundleOptions.GetBundleOptionPlatforms.ResponseBody>(
      registrarPaths.BundleOptions.GetBundleOptionPlatforms(),
      { enabled: !model },
    );

  const allPlatforms: Platform[] | undefined = _allPlatforms?.platforms;

  const { result: modelPlatforms, isFetching: isFetchingModelPlatforms } =
    useApi<Models.GetModelPlatform.ResponseBody>(
      nurseryPaths.Models.GetModelPlatform(model ?? 'Ioniq'),
      { enabled: !!model },
    );

  const isFetching = model ? isFetchingModelPlatforms : isFetchingAllPlatforms;

  const platforms = useMemo(
    () =>
      (model
        ? modelPlatforms?.map(
            (platform) => ({ key: platform, name: platform } as Platform),
          )
        : allPlatforms) || [],
    [model, modelPlatforms, allPlatforms],
  );

  const value = useMemo(
    () => find(platforms, ['key', propsValue]),
    [platforms, propsValue],
  );

  useEffect(
    function updateValueOnOptionsChange() {
      if (isFetching) return;

      let newKey = propsValue;
      if (!platforms.some((platform) => platform.key === propsValue)) {
        newKey = undefined;
      }
      if (required && platforms.length === 1 && !newKey) {
        newKey = platforms[0].key;
      }
      if (newKey !== propsValue) {
        onSelect(newKey);
      }
    },
    [isFetching, platforms, propsValue, required, onSelect],
  );

  const renderLabel = (platform: Platform) => platform.name;

  const handleOnSelect = (platform: Platform) => {
    onSelect(platform?.key);
  };

  return (
    <Select<Platform>
      {...selectProps}
      className={className}
      label={label}
      required={required}
      disabled={disabled}
      value={value}
      onSelect={handleOnSelect}
      optionKeyPath="key"
      renderListOption={renderLabel}
      options={platforms}
    />
  );
}

export default SelectPlatform;

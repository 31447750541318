import { FleetVehicle } from '@motional-cc/fe/interface/api/armada';
import { CellProps } from './interface';
import VehiclePartnersCell from './VehiclePartnersCell';

function VehiclePartnerVehicleIdsCell(props: CellProps<FleetVehicle>) {
  return (
    <VehiclePartnersCell
      {...props}
      PartnerComponent={({ partner }) => <>{partner.carId}</>}
    />
  );
}

export default VehiclePartnerVehicleIdsCell;

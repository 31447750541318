import { getIsoDateParts } from './getIsoDateParts';

export function getDateFromIso(
  isoDate: string,
  { asTimezone = 'original' }: { asTimezone?: 'original' | 'client' } = {},
): Date {
  if (asTimezone === 'client') {
    return new Date(isoDate);
  }

  const { year, month, day, hours, minutes, seconds } =
    getIsoDateParts(isoDate);

  if (typeof year !== 'number' || typeof month !== 'number') {
    // TODO: return null and fix all the issues that arise from that 🙈
    return new Date();
  }

  return new Date(year, month - 1, day, hours, minutes, seconds);
}

import clsx from 'clsx';
import {
  ChangeEvent,
  DOMAttributes,
  InputHTMLAttributes,
  ReactNode,
  RefObject,
  forwardRef,
  useRef,
} from 'react';
import { PublicProps as FieldWrapperProps } from 'src/components/common/FieldWrapper';
import Icon from 'src/components/common/Icon';
import { isNullish } from 'src/tools/types';
import FieldWrapper from './FieldWrapper';
import './InputField.scss';

export type InputProps = FieldWrapperProps & {
  value?: string | number | boolean | null | undefined;
  className?: string;
  allowClear?: boolean;
  icon?: ReactNode;
  pattern?: RegExp;
  onBlur?: (newValue: string) => void;
  onFocus?: (newValue: string) => void;
  onChange?: (newValue: string) => void;
  onClear?: () => void;
} & Pick<
    InputHTMLAttributes<HTMLInputElement>,
    'autoFocus' | 'type' | 'min' | 'max' | 'inputMode' | 'lang' | 'autoComplete'
  > &
  Pick<
    DOMAttributes<HTMLInputElement>,
    'onMouseEnter' | 'onMouseLeave' | 'onKeyDown' | 'onMouseDown' | 'onClick'
  >;

function InputField(
  {
    value,
    className,
    allowClear,
    isStatic,
    icon: renderIcon,
    autoFocus = false,
    type = 'text',
    disabled,
    hideLabel,
    inputMode,
    errorMessage,
    label,
    lang,
    min,
    max,
    pattern,

    onMouseEnter,
    onMouseLeave,
    onFocus,
    onBlur,
    onKeyDown,
    onMouseDown,
    onClick,
    onChange,
    onClear,

    ...fieldWrapperProps
  }: InputProps,
  passedRef: RefObject<HTMLInputElement>,
) {
  const backupRef = useRef<HTMLInputElement>();
  const ref = passedRef ?? backupRef;

  const patternString = pattern?.toString().slice(1, -1);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (disabled || !onChange) {
      return;
    }
    onChange(event.target.value);
  };

  const handleInputBlur = (event: ChangeEvent<HTMLInputElement>) => {
    if (disabled || !onBlur) {
      return;
    }
    onBlur(event.target.value);
  };

  const handleInputFocus = (event: ChangeEvent<HTMLInputElement>) => {
    if (disabled || !onFocus) {
      return;
    }
    onFocus(event.target.value);
  };

  const renderStartIcon = renderIcon ? () => renderIcon : undefined;
  const renderEndIcon =
    value && allowClear && !isStatic
      ? () => <Icon name="MultiplyFat" />
      : undefined;
  const endAdornmentLabel = value && allowClear ? 'Clear input' : undefined;

  const handleClearClick =
    value && allowClear && !isStatic
      ? () => {
          onClear?.();
          onChange?.('');
          ref?.current?.focus();
        }
      : undefined;

  return (
    <FieldWrapper
      {...fieldWrapperProps}
      isStatic={isStatic}
      errorMessage={errorMessage}
      label={label}
      hideLabel={hideLabel}
      className={clsx([className, 'input-field'])}
      disabled={disabled}
      startAdornmentClassName={'input-field__icon'}
      renderStartIcon={renderStartIcon}
      endAdornmentClassName={'input-field__action'}
      renderEndIcon={renderEndIcon}
      endAdornmentLabel={endAdornmentLabel}
      onEndAdornmentClick={handleClearClick}
    >
      {({ className, id, errorId, disabled, required, placeholder }) => (
        <input
          ref={ref}
          id={id}
          type={type}
          value={isNullish(value) ? '' : String(value)}
          lang={lang}
          min={min}
          max={max}
          readOnly={isStatic}
          inputMode={inputMode}
          onChange={handleInputChange}
          onClick={onClick}
          onMouseDown={onMouseDown}
          onKeyDown={onKeyDown}
          autoFocus={autoFocus}
          onBlur={handleInputBlur}
          onFocus={handleInputFocus}
          aria-label={hideLabel ? label : undefined}
          aria-invalid={errorId ? true : undefined}
          aria-errormessage={errorId}
          disabled={disabled}
          required={required}
          placeholder={placeholder}
          pattern={patternString}
          spellCheck={false}
          className={clsx([
            className,
            'input-field__input',
            { 'input-field__input--is-empty': !value },
            { 'input-field__input--is-static': isStatic },
          ])}
        />
      )}
    </FieldWrapper>
  );
}

export default forwardRef(InputField);

import { Model } from '@motional-cc/fe/interface/api/registrar';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { CellProps } from 'src/components/common/Table/interface';
import TableCell, {
  CONTENT_CLASS,
} from 'src/components/common/Table/TableCell';
import unknownImage from 'src/static/img/vehicle-birdseye/2dp/unknown.svg';
import './CarModelCell.scss';

const availableVehicleImages: Model[] = ['Audi', 'BMW', 'Ioniq', 'Pacifica'];

function CarModelCell({ cell: carModelName, column }: CellProps<Model>) {
  const [imgSrc, setImgSrc] = useState<string>(unknownImage);

  useEffect(
    function loadAndSetImageSrc() {
      if (!availableVehicleImages.includes(carModelName)) return;

      (async () => {
        const svgModule = await import(
          `../../../static/img/vehicle-birdseye/2dp/${carModelName}.svg`
        );
        setImgSrc(svgModule.default);
      })();
    },
    [carModelName],
  );

  return (
    <TableCell
      column={column}
      className={clsx(['car-model-cell', 'the-table__cell--text'])}
    >
      <span className={clsx([CONTENT_CLASS, 'car-model-cell__cell-content'])}>
        {carModelName && (
          <>
            <img
              className="car-model-cell__img"
              src={imgSrc}
              alt=""
              role="presentation"
            />
            <span className="car-model-cell__model-name">{carModelName}</span>
          </>
        )}
      </span>
    </TableCell>
  );
}

export default CarModelCell;

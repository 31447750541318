import { PartnerName } from '@motional-cc/fe/interface/api/api-concierge';
import t from '@motional-cc/fe/tools/translate';
import { OrString } from 'src/interface/utility';

// Using string as forcing the real types makes usage complicated
// partner: PartnerName, partnerServices?: PartnerJobPreference[]
export const renderPartnerLabel = (
  partner?: OrString<PartnerName> | null,
  partnerServices?: string[] | null,
) => {
  if (!partner) {
    return 'Partner';
  }

  let renderedLabel;

  if (partnerServices?.length === 1) {
    renderedLabel = t(`subPartner.${partner}.${partnerServices[0]}`);
  }

  if (!renderedLabel) {
    renderedLabel = t(`partner.${partner}`);
  }

  return renderedLabel || partner;
};

import clsx from 'clsx';
import './SelectListOptionDualLabel.scss';

interface Props {
  className?: string;
  primaryLabel: string;
  secondaryLabel?: string | undefined;
}

function SelectListOptionDualLabel({
  className,
  primaryLabel,
  secondaryLabel,
}: Props) {
  return (
    <span className={clsx([className, 'select-list-option-dual-label'])}>
      <span className="select-list-option-dual-label__primary-label">
        {primaryLabel}
      </span>
      {secondaryLabel && (
        <span className="select-list-option-dual-label__secondary-label">
          {secondaryLabel}
        </span>
      )}
    </span>
  );
}

export default SelectListOptionDualLabel;

import clsx from 'clsx';
import { forwardRef, ReactNode, Ref, useId } from 'react';
import './Radio.scss';

export const IS_RADIO_SYMBOL = Symbol('Radio');

interface Props<Value> {
  name?: string;
  label?: ReactNode;
  hideLabel?: boolean;
  value: Value;
  checked?: boolean;
  disabled?: boolean;
  className?: string;
  onChange?: (value: Value) => void;
}

function Radio<Value>(
  {
    name,
    label,
    hideLabel,
    value,
    checked = false,
    disabled,
    className,
    onChange,
  }: Props<Value>,
  ref: Ref<HTMLLabelElement>,
) {
  const id = useId();

  const handleChange = () => {
    onChange?.(value);
  };

  return (
    <label
      ref={ref}
      htmlFor={id}
      className={clsx([
        'the-radio-button',
        { 'the-radio-button--checked': checked },
        { 'the-radio-button--unchecked': !checked },
        { 'the-radio-button--disabled': disabled },
        className,
      ])}
    >
      <input
        type="radio"
        id={id}
        name={name}
        onChange={handleChange}
        checked={checked}
        disabled={disabled && !checked}
        className="the-radio-button__input"
      />
      <span className="the-radio-button__radio-container">
        <i className="the-radio-button__radio">
          <span className="the-radio-button__radio-fill"></span>
        </i>
      </span>
      {label && (
        <span
          className={clsx([
            'the-radio-button__label',
            { 'the-radio-button__label--hidden': hideLabel },
          ])}
        >
          {label}
        </span>
      )}
    </label>
  );
}

Radio.symbol = IS_RADIO_SYMBOL;

export default forwardRef(Radio);

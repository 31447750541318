import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useState,
} from 'react';
import { Theme, ThemeOption } from 'src/interface/command-center';
import { hex } from 'src/tools/colors/hex';
import useMediaQuery from 'src/tools/hooks/useMediaQuery';
import { toLowerCase } from 'src/tools/string/toLowerCase';

type ThemeState = {
  theme: Theme;
  setTheme: (theme: ThemeOption) => void;
};
const defaultThemeState: ThemeState = {
  theme: 'LIGHT',
  setTheme: () => null,
};
const THEME_KEY = 'theme';
const ThemeStateContext = createContext<ThemeState>(defaultThemeState);

export const DARK_CLASS = 'main-theme--dark';
export const LIGHT_CLASS = 'main-theme--light';

export const changeTheme = (theme: Theme) => {
  const themeMeta = document.querySelector('#theme-color-meta');

  if (theme === 'LIGHT') {
    document.documentElement.classList.remove(DARK_CLASS);
    document.documentElement.classList.add(LIGHT_CLASS);
    themeMeta?.setAttribute('content', hex('hyundaiBlue'));
  }
  if (theme === 'DARK') {
    document.documentElement.classList.remove(LIGHT_CLASS);
    document.documentElement.classList.add(DARK_CLASS);
    themeMeta?.setAttribute('content', hex('noirAmbient95'));
  }
};

type Props = {
  children: ReactNode;
};
export function ThemeProvider({ children }: Props) {
  const prefersDark = useMediaQuery('(prefers-color-scheme: dark)');
  const [themeOption, setThemeOption] = useState<ThemeOption>(
    (localStorage.getItem(THEME_KEY) as ThemeOption | undefined) ?? 'AUTO',
  );
  const theme: Theme =
    themeOption === 'DARK' || (themeOption === 'AUTO' && prefersDark)
      ? 'DARK'
      : 'LIGHT';

  const setTheme = useCallback((newTheme: ThemeOption) => {
    setThemeOption(newTheme);
    localStorage.setItem(THEME_KEY, newTheme);
  }, []);

  useLayoutEffect(() => {
    changeTheme(theme);
  }, [theme]);

  return (
    <ThemeStateContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeStateContext.Provider>
  );
}

export function useTheme() {
  const { theme, setTheme } = useContext(ThemeStateContext);

  return { theme, lowercaseTheme: toLowerCase(theme), setTheme } as const;
}

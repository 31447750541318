import { Vehicles, urls } from '@motional-cc/fe/interface/api/api-concierge';
import { useEffect, useState } from 'react';
import { concierge } from 'src/api/hosts';
import { inMilliseconds } from 'src/tools/date-time/inMilliseconds';
import { useApi } from './hooks/service';
import { convertToAbsolutePaths } from './utils';

export const ACTIVE_REFETCH_INTERVAL = inMilliseconds(3, 'seconds');
export const PASSIVE_REFETCH_INTERVAL = inMilliseconds(1, 'minute');

export const conciergePaths = convertToAbsolutePaths(concierge, urls);

const useDetailedSection = (carId?: string, sectionId?: string) => {
  const [isActive, setIsActive] = useState(false);

  const response = useApi<Vehicles.GetTimelineSection.ResponseBody>(
    conciergePaths.Vehicles.GetTimelineSection(carId ?? '', sectionId ?? ''),
    {
      enabled: !!(carId && sectionId),
      refetchInterval: isActive
        ? ACTIVE_REFETCH_INTERVAL
        : PASSIVE_REFETCH_INTERVAL,
      knownErrorCodes: ['invalid_uuid', 'timeline_section_not_found'],
    },
  );

  useEffect(
    function syncActiveStateToOptimisePolling() {
      setIsActive(!!response?.result?.is_active);
    },
    [response?.result?.is_active],
  );

  return {
    ...response,
    section: response?.result,
  };
};

export const conciergeApi = {
  useDetailedSection,
};

import { DetailedUserWithScopes } from '@motional-cc/fe/interface/api/user-profile-service';
import { env } from 'src/api/hosts';
import { inMilliseconds } from 'src/tools/date-time/inMilliseconds';
import { renderDateTime } from 'src/tools/date-time/renderDateTime';
import { SESSION_TRACE_PARENT, traceMap } from 'src/tools/tracing/constants';

const dataSource = ['com', 'prd'].includes(String(env))
  ? 'ch-dp-event-store-production'
  : 'ch-dp-event-store-staging';

const buildGrafanaTraceLink = (focusedVehicleId: string) =>
  `https://grafana.lens.motional.com/d/a02c290f-183d-4e41-85f5-46ab3c6b78da/what-s-happening-on-vehicle?${new URLSearchParams(
    [
      ['orgId', '1'],
      ['from', String(Date.now() - inMilliseconds(5, 'minutes'))],
      ['to', String(Date.now() + inMilliseconds(1, 'minute'))],
      ['var-datasource', dataSource],
      ['var-vehicle_id', focusedVehicleId],
    ],
  )}`;

export const buildGrfanaUserSessionLink = (
  userProfile?: DetailedUserWithScopes,
) =>
  `https://grafana.lens.motional.com/d/d83582fc-c835-4d5f-b2de-0ec10a565e06/traces?${new URLSearchParams(
    [
      ['var-env', String(env)],
      ['var-datasource', dataSource],
      ['var-cc_session_id', SESSION_TRACE_PARENT],
      ...(!!userProfile && userProfile?.email
        ? [['var-requestors', userProfile.email]]
        : []),
      ['from', String(Date.now() - inMilliseconds(5, 'minutes'))],
      ['to', String(Date.now() + inMilliseconds(1, 'minute'))],
      ['orgId', '1'],
      ['var-root_only', 'false'],
      ['var-includeIOT', 'true'],
      ['var-service_name', 'All'],
      ['var-http_method', 'All'],
      ['var-operation_name', 'All'],
      ['var-status_code', 'All'],
    ],
  )}`;

export const sendFeedback = <PromiseReturn>({
  appSection,
  details,
  focusedVehicleId,
  userProfile,
  sendFeedback,
}: {
  appSection: string;
  details: string;
  focusedVehicleId?: string;
  userProfile?: DetailedUserWithScopes;
  sendFeedback: (args: {
    Page: string;
    Details: string;
    Traces: string;
    Section: string;
    Vehicle: string;
    SessionID: string;
    UserEmail: string;
    UserName: string;
    GrafanaVehicleStatusLink: string;
    GrafanaUserSessionLink: string;
  }) => Promise<PromiseReturn>;
}) =>
  sendFeedback({
    Page: location.href,
    Details: details || 'No details provided.',
    Traces: `${[...traceMap.latest]
      .map(
        ({ url, traceId, callCount, timestamp }) =>
          `${renderDateTime(timestamp, {
            format: 'ISOTime',
          })} - ${url} [${callCount}]\n${traceId}`,
      )
      .reverse()
      .join('\n\n')}`,
    Section: appSection,
    Vehicle: focusedVehicleId || 'No vehicle focused',
    SessionID: SESSION_TRACE_PARENT,
    UserEmail:
      !!userProfile && userProfile?.email
        ? userProfile.email
        : 'robin.yeh@motional.com',
    UserName: `${
      !!userProfile && userProfile?.email ? userProfile.name : 'Anonymous'
    }${userProfile ? '' : ' (don’t contact)'}`,
    GrafanaVehicleStatusLink: focusedVehicleId
      ? buildGrafanaTraceLink(focusedVehicleId)
      : 'No vehicle focused',
    GrafanaUserSessionLink: buildGrfanaUserSessionLink(userProfile),
  });

export var urls;
(function (urls) {
    let PublicKey;
    (function (PublicKey) {
        PublicKey.PublicKeyList = () => `/public-key`;
    })(PublicKey = urls.PublicKey || (urls.PublicKey = {}));
    let Subscription;
    (function (Subscription) {
        Subscription.SubscriptionCreate = () => `/subscription`;
        Subscription.SubscriptionDetail = (subscriptionId) => `/subscription/${subscriptionId}`;
        Subscription.SubscriptionDelete = (subscriptionId) => `/subscription/${subscriptionId}`;
        Subscription.NotificationCreate = (subscriptionId) => `/subscription/${subscriptionId}/notification`;
    })(Subscription = urls.Subscription || (urls.Subscription = {}));
    let Health;
    (function (Health) {
        Health.HealthList = () => `/health`;
    })(Health = urls.Health || (urls.Health = {}));
})(urls || (urls = {}));

import clsx from 'clsx';
import compact from 'lodash/compact';
import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import { useEffect, useMemo, useRef, useState } from 'react';
import { userApi } from 'src/api/user';
import ButtonField from 'src/components/common/ButtonField';
import FullWidthLoader from 'src/components/common/FullWidthLoader';
import Icon from 'src/components/common/Icon';
import SelectAndOrder from 'src/components/common/SelectAndOrder';
import useModal from 'src/components/Modal/modal-hook';
import {
  TableSettingName,
  TableSettings,
  useUserProfileSetting,
} from 'src/contexts/profile-settings-context';
import { Immutable } from 'src/interface/utility';
import usePrevious from 'src/tools/hooks/usePrevious';
import './TableColumnSelect.scss';

const DEFAULT_MODAL_TITLE = 'Customize columns';

interface Props<SettingName extends TableSettingName> {
  settingName: SettingName;
  /** @prop all of the columns to choose from. columns will default to being in this order */
  columnNames: Readonly<TableSettings[SettingName][]>;
  defaultSelected?: Readonly<TableSettings[SettingName][]>;
  disabledColumns?: Readonly<TableSettings[SettingName][]>;
  className?: string;
  renderLabel?: (columnName: TableSettings[SettingName]) => string;
  onCloseSelectModal?: () => void;
  onResetColumnsClick?: () => void;
  onColumnChange?: (
    clickedColumnName: TableSettings[SettingName],
    isChecked: boolean,
  ) => void;
}

function TableColumnSelect<SettingName extends TableSettingName>({
  settingName,
  disabledColumns,
  columnNames,
  defaultSelected,
  className,
  renderLabel = (columnName) => columnName,
  onCloseSelectModal,
  onResetColumnsClick,
  onColumnChange,
}: Props<SettingName>) {
  const {
    ModalComponent: ColumnSelectModal,
    openModal: openColumnSelectModal,
  } = useModal();

  const newColumns = useRef<TableSettings[SettingName][]>([]);
  const { status: userProfileStatus } = userApi.useUserProfile();
  const previousUserProfileStatus = usePrevious(userProfileStatus);
  const [saveCount, setSaveCount] = useState(1);

  const { setting: persistedColumnOrder, setSetting: setColumnOrder } =
    useUserProfileSetting(`${settingName}-table-column-order`);
  type ColumnOrder = Parameters<Parameters<typeof setColumnOrder>[0]>[0];
  const displayedColumnOrder = compact(persistedColumnOrder ?? columnNames);

  const { setting: persistedSelectedColumns, setSetting: setSelectedColumns } =
    useUserProfileSetting(`${settingName}-table-columns`);
  type SelectedColumns = Parameters<
    Parameters<typeof setSelectedColumns>[0]
  >[0];
  const displayedSelectedColumns = compact(
    persistedSelectedColumns ?? defaultSelected ?? columnNames,
  );

  const isDefault = useMemo(
    () =>
      userProfileStatus !== 'success' ||
      (isEqual(displayedColumnOrder, columnNames) &&
        isEqual(
          sortBy(displayedSelectedColumns),
          sortBy(defaultSelected ?? columnNames),
        )),
    [
      displayedColumnOrder,
      columnNames,
      defaultSelected,
      displayedSelectedColumns,
      userProfileStatus,
    ],
  );

  useEffect(
    function syncColumnOrder() {
      if (!persistedColumnOrder) return;

      setColumnOrder((currentOrder) => {
        // TODO: Work out why there needs to be so many type casts
        const defaultOrder_retyped = columnNames as Immutable<ColumnOrder>;
        // save any columns that didn’t previously exist so we can add them to the selected list
        newColumns.current = difference(
          defaultOrder_retyped,
          currentOrder ?? [],
        ) as TableSettings[SettingName][];

        const newOrder = [
          // Put all the existing correct columns first…
          ...intersection(currentOrder, defaultOrder_retyped),
          // … followed by the missing columns
          ...newColumns.current,
        ] as ColumnOrder;

        // don’t send requests to the back-end when values haven’t changes
        if (isEqual(newOrder, currentOrder)) {
          return currentOrder;
        } else {
          return newOrder;
        }
      });
    },
    [columnNames, setColumnOrder, persistedColumnOrder],
  );

  useEffect(
    function syncSelectedColumns() {
      if (!persistedSelectedColumns) return;

      setSelectedColumns((currentlySelected) => {
        // TODO: Work out why we need this type cast
        const updatedSelection: SelectedColumns | undefined = uniq([
          ...intersection(compact(persistedSelectedColumns), columnNames),
          ...newColumns.current,
        ]) as SelectedColumns;

        // Clear this to not keep re-picking them
        newColumns.current = [];

        if (updatedSelection?.length === 0) {
          return undefined;
        }
        // don’t send requests to the back-end when values haven’t changes
        else if (isEqual(sortBy(currentlySelected), sortBy(updatedSelection))) {
          return currentlySelected;
        } else {
          return updatedSelection;
        }
      });
    },
    [persistedSelectedColumns, columnNames, setSelectedColumns],
  );

  useEffect(
    function countSaves() {
      if (
        userProfileStatus !== 'success' ||
        userProfileStatus === previousUserProfileStatus
      ) {
        return;
      }
      setSaveCount((count) => count + 1);
    },
    [previousUserProfileStatus, userProfileStatus],
  );

  const handleOrderChange = (newOrder: TableSettings[SettingName][]) => {
    setColumnOrder(() => newOrder as ColumnOrder);
  };

  const handleSelectionChange = (
    newSelection: TableSettings[SettingName][],
  ) => {
    setSelectedColumns(() => newSelection as SelectedColumns);
    // We need to store all of the known columns,
    //   so we know which ones are new and can pick them
    setColumnOrder(
      (currentOrder) => currentOrder ?? (displayedColumnOrder as ColumnOrder),
    );
  };

  const handleResetColumnsClick = () => {
    setColumnOrder(() => undefined);
    setSelectedColumns(() => undefined);

    onResetColumnsClick?.();
  };

  const handleCloseSelectModal = () => {
    onCloseSelectModal?.();
  };

  return (
    <>
      <ButtonField
        className={clsx([className, 'table-column-select-toggle'])}
        label={<Icon name="Columns3" />}
        onClick={openColumnSelectModal}
      />

      <ColumnSelectModal
        title={DEFAULT_MODAL_TITLE}
        onClose={handleCloseSelectModal}
      >
        {() => (
          <div className="table-column-select">
            <p className="table-column-select__pick-count">
              <span>
                {displayedSelectedColumns.length === columnNames.length
                  ? `All ${displayedSelectedColumns.length} visible`
                  : `${displayedSelectedColumns.length} visible of ${columnNames.length} available`}
              </span>
              <span
                className={clsx([
                  'table-column-select__saved',
                  `table-column-select__saved--state-${saveCount % 0}`,
                ])}
              >
                Saved
              </span>

              <ButtonField
                label="Reset to default"
                onClick={handleResetColumnsClick}
                size="small"
                color="text"
                disabled={isDefault}
              />
            </p>

            {userProfileStatus === 'success' ? (
              <SelectAndOrder<TableSettings[SettingName]>
                disabledColumns={disabledColumns}
                renderLabel={renderLabel}
                onOrderChange={handleOrderChange}
                onSelectionChange={handleSelectionChange}
                onColumnChange={onColumnChange}
                columnOrder={
                  displayedColumnOrder as TableSettings[SettingName][]
                }
                selectedColumns={
                  displayedSelectedColumns as TableSettings[SettingName][]
                }
              />
            ) : (
              <FullWidthLoader />
            )}
          </div>
        )}
      </ColumnSelectModal>
    </>
  );
}

export default TableColumnSelect;

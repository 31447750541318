import { SummaryState } from '@motional-cc/fe/interface/api/armada';
import t from '@motional-cc/fe/tools/translate';
import clsx from 'clsx';
import Icon, { IconName } from 'src/components/common/Icon';
import Tooltip from 'src/components/common/Tooltip';
import './VehicleStatusDot.scss';

export const ICON_MAP: {
  [status in SummaryState]: IconName;
} = {
  PREPOSITION: 'Crosshairs',
  PARTIALLY_ACTIVE: 'MoonHalf',
  AWAITING_DISPATCH: 'CircleDashedWithCircle',
  UNAVAILABLE: 'CarFrontWithMultiply',
  ENROUTE_TO_PICKUP: 'ChevronRightBesidePin',
  ARRIVED_AT_PICKUP: 'PinWithTick',
  ENROUTE_TO_DROPOFF: 'RoadPerspectiveWithPin',
  ARRIVED_AT_DROPOFF: 'CarSideBesidePin',
  INACTIVE: 'Minus',
  UNKNOWN: 'CarFrontWithQuestion',
};

interface Props {
  summaryState: SummaryState;
  className?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

function VehicleStatusDot({
  summaryState,
  className,
  onMouseEnter,
  onMouseLeave,
}: Props) {
  const iconName = ICON_MAP[summaryState];
  return (
    <Tooltip
      content={t(`vehicleSummaryState.label.${summaryState}`) || summaryState}
    >
      <i
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={clsx([
          'status-dot',
          `status-dot--summary-state-${summaryState}`,
          className,
        ])}
      >
        <Icon hideIfEmpty className="status-dot__icon" name={iconName} />
      </i>
    </Tooltip>
  );
}

export default VehicleStatusDot;

import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { env } from 'src/api/hosts';
import FullWidthLoader from 'src/components/common/FullWidthLoader';
import { router } from './router';
import './App.scss';

function App() {
  useEffect(function throwIfNoEnvIsSet() {
    if (
      (!env || env === 'PLEASE_SET') &&
      globalThis.location.origin.includes('//localhost:')
    ) {
      throw new Error('Environment must be set while developing.');
    }
  }, []);

  return (
    <RouterProvider router={router} fallbackElement={<FullWidthLoader />} />
  );
}

export default App;

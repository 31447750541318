import { Vehicles } from '@motional-cc/fe/interface/api/api-server';
import { FleetVehicle } from '@motional-cc/fe/interface/api/armada';
import compact from 'lodash/compact';
import find from 'lodash/find';
import uniq from 'lodash/uniq';
import { useCallback, useMemo } from 'react';
import { apiServerPaths } from 'src/api/api-server';
import { armadaApi } from 'src/api/armada';
import { useApi } from 'src/api/hooks/service';
import { combineQueryStatuses } from 'src/api/utils';
import { TableSort } from 'src/components/common/Table/interface';
import { QueryParams } from 'src/interface/utility';
import { objectFromEntries } from 'src/tools/object/objectFromEntries';

const ESTIMATED_ROW_HEIGHT = 50;
const ROW_COUNT_TO_FILL_WINDOW = Math.max(
  15,
  Math.ceil(window.innerHeight / ESTIMATED_ROW_HEIGHT),
);

interface Props {
  sort?: TableSort;
  searchTerm?: string;
}

function useVehicleRegistrationVehicles({ sort, searchTerm }: Props = {}) {
  const params: QueryParams = {};
  if (sort) {
    params.sort = `${sort.on}:${
      sort.direction === 'ascending' ? 'asc' : 'desc'
    }`;
  }

  const {
    result: vehicles = [],
    isFetching: isFetchingVehicles,
    fetchMore: fetchMoreVehicles,
    canFetchMore: canFetchMoreVehicles,
    error: vehicleRequestError,
    status: vehicleRequestStatus,
    refetch: refetchVehicles,
  } = armadaApi.useVehicles({
    filters: searchTerm
      ? [
          {
            key: 'carSearch',
            check: 'contains',
            values: [searchTerm],
          },
        ]
      : [],
    params,
    pageSize: ROW_COUNT_TO_FILL_WINDOW,
    keepPreviousData: true,
  });

  const {
    result: partnerVehicles,
    refetch: refetchPartnerVehicles,

    error: vehiclePartnerRequestError,
    status: vehiclePartnerRequestStatus,
  } = useApi<Vehicles.PartnersList.ResponseBody>(
    apiServerPaths.Vehicles.PartnersList(),
  );

  const allVehicles = useMemo(() => {
    const allVins = uniq([
      ...(vehicles?.map((vehicle) => vehicle.vin) || []),
      // TODO: work out a better way to search. Maybe use VIN when it’s available in armada
      ...(!searchTerm && partnerVehicles
        ? partnerVehicles.map((vehicle) => vehicle.vin)
        : []),
    ]);

    return compact(
      allVins.map((vin) => {
        const vehicle = find(vehicles, ['vin', vin]);
        const partnerVehicle = find(partnerVehicles, ['vin', vin]);

        return (
          vehicle ||
          (partnerVehicle
            ? {
                ...partnerVehicle,
                partners: objectFromEntries(
                  partnerVehicle.partners.map((partner) => [
                    partner.name,
                    { ...partner, carId: partner.partner_vehicle_id },
                  ]),
                ),
              }
            : undefined)
        );
      }),
    ) as FleetVehicle[];
  }, [partnerVehicles, vehicles, searchTerm]);

  const errors = useMemo(() => {
    const errors = compact([vehicleRequestError, vehiclePartnerRequestError]);
    return errors.length ? errors : null;
  }, [vehiclePartnerRequestError, vehicleRequestError]);

  const refetch = useCallback(() => {
    refetchVehicles();
    refetchPartnerVehicles();
  }, [refetchPartnerVehicles, refetchVehicles]);

  return {
    vehicles: allVehicles,
    isFetchingVehicles,
    refetch,
    errors,
    canFetchMoreVehicles,
    fetchMoreVehicles,
    status: combineQueryStatuses(
      vehicleRequestStatus,
      vehiclePartnerRequestStatus,
    ),
  };
}

export default useVehicleRegistrationVehicles;

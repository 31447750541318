import {
  FloatingFocusManager,
  FloatingPortal,
  Placement,
  UseFloatingReturn,
  autoPlacement,
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import clsx from 'clsx';
import { CSSProperties, ReactNode, useState } from 'react';
import './Popover.scss';

interface GutsProps {
  id?: string;
  className?: string;
  contentClassName?: string;
  matchTargetWidth?: boolean;
  children: ReactNode;

  floatingUi: UseFloatingReturn<HTMLElement>;
  targetWidth: number;
}

function PopoverGuts({
  id,
  className,
  contentClassName,
  children,
  matchTargetWidth,
  floatingUi,
  targetWidth,
}: GutsProps) {
  const { getFloatingProps } = useInteractions([
    useRole(floatingUi.context),
    useClick(floatingUi.context),
    useDismiss(floatingUi.context),
  ]);

  const style: CSSProperties = {
    position: floatingUi.strategy,
    left: floatingUi.x ?? 0,
    top: floatingUi.y ?? 0,
  };

  if (matchTargetWidth) {
    style.width = targetWidth;
  }

  return (
    <div
      ref={floatingUi.refs.setFloating}
      id={id}
      style={style}
      className={clsx([
        className,
        'the-popover',
        `the-popover--side-${floatingUi.placement.split('-')[0]}`,
        `the-popover--placement-${floatingUi.placement}`,
      ])}
      {...getFloatingProps()}
    >
      <div className={clsx([contentClassName, 'the-popover__content'])}>
        {children}
      </div>
    </div>
  );
}

interface Props extends Omit<GutsProps, 'floatingUi' | 'targetWidth'> {
  style?: CSSProperties;
  target: HTMLElement | null;
  placement?: Placement[] | Placement | undefined;
  allowTabOut?: boolean;
  open?: boolean;
  onClose?: () => void;
  closeOn?: 'mouseDown' | 'click' | 'mouseUp';
}

function Popover({
  onClose,
  target,
  placement,
  open = true,
  allowTabOut = false,
  ...gutsProps
}: Props) {
  const [targetWidth, setTargetWidth] = useState(0);
  const middleware = [
    offset(8),
    shift(),
    size({
      apply({ rects }) {
        setTargetWidth(rects.reference.width);
      },
    }),
  ];
  if (!placement) {
    middleware.push(autoPlacement());
  } else if (Array.isArray(placement)) {
    middleware.push(autoPlacement({ allowedPlacements: placement }));
  } else {
    middleware.push(flip());
  }

  const floatingUi = useFloating<HTMLElement>({
    open,
    onOpenChange: (open) => !open && onClose?.(),
    placement: placement && !Array.isArray(placement) ? placement : undefined,
    strategy: 'fixed',
    middleware,
    whileElementsMounted: autoUpdate,
    elements: {
      reference: target,
    },
  });

  return (
    <FloatingPortal>
      {open &&
        (allowTabOut ? (
          <PopoverGuts
            {...gutsProps}
            floatingUi={floatingUi}
            targetWidth={targetWidth}
          />
        ) : (
          <FloatingFocusManager
            context={floatingUi.context}
            order={['reference', 'content']}
          >
            <PopoverGuts
              {...gutsProps}
              floatingUi={floatingUi}
              targetWidth={targetWidth}
            />
          </FloatingFocusManager>
        ))}
    </FloatingPortal>
  );
}

export default Popover;

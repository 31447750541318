import { FleetVehicle } from '@motional-cc/fe/interface/api/armada';
import t from '@motional-cc/fe/tools/translate';
import { CellProps } from './interface';
import VehiclePartnersCell from './VehiclePartnersCell';

function VehiclePartnerPreferencesCell(props: CellProps<FleetVehicle>) {
  return (
    <VehiclePartnersCell
      {...props}
      PartnerComponent={({ partner }) => (
        <>
          {partner.jobPreference
            ? t(`partnerService.${partner.jobPreference}`) ||
              partner.jobPreference
            : null}
        </>
      )}
    />
  );
}

export default VehiclePartnerPreferencesCell;

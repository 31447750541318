import { useEffect, useState } from 'react';
import { inMilliseconds } from 'src/tools/date-time/inMilliseconds';
import { useWindowIsActive } from './window';

const DEFAULT_INACTIVE_TIMEOUT = inMilliseconds(1, 'minute');

export function useUserIsActive(inactivityTimout = DEFAULT_INACTIVE_TIMEOUT) {
  const windowIsActive = useWindowIsActive();
  const [userIsActive, setUserIsActive] = useState(windowIsActive);

  useEffect(
    function setUserAsInactiveDuringNoMouseOrKeyboardUsage() {
      let timeout: number;

      const setUserAsActive = () => {
        setUserIsActive(true);

        window.clearTimeout(timeout);
        timeout = window.setTimeout(function setUserAsInactive() {
          setUserIsActive(false);
        }, inactivityTimout);
      };

      window.addEventListener('mousemove', setUserAsActive);
      window.addEventListener('keydown', setUserAsActive);
      setUserAsActive();

      return function clearTimer() {
        window.clearTimeout(timeout);
      };
    },
    [inactivityTimout],
  );

  return windowIsActive && userIsActive;
}

import get from 'lodash/get';

// Scroll into view is not really testable is jest
/* istanbul ignore next */
export const scrollIntoView = (
  listElement: HTMLElement,
  optionElement: HTMLElement,
) => {
  const listBounds = listElement.getBoundingClientRect();
  const optionBounds = optionElement.getBoundingClientRect();

  if (optionBounds.bottom > listBounds.bottom) {
    listElement.scrollTop = Math.min(
      optionElement.offsetTop +
        optionElement.clientHeight -
        listElement.offsetHeight,
      listElement.scrollHeight,
    );
  } else if (optionBounds.top < listBounds.top) {
    listElement.scrollTop = Math.max(
      listElement.scrollTop - optionBounds.height,
      0,
    );
  }
};

export const getOptionKey = <Option>(
  option: Option,
  optionKeyPath?: string,
) => {
  return optionKeyPath ? get(option, optionKeyPath, option) : option;
};

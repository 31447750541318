export var urls;
(function (urls) {
    let Vehicles;
    (function (Vehicles) {
        Vehicles.ListVehicles = () => `/public/vehicles`;
        Vehicles.GetTimelineSummary = (vehicleId) => `/public/vehicles/${vehicleId}/timeline/summary`;
        Vehicles.GetTimelineSection = (vehicleId, timelineId) => `/public/vehicles/${vehicleId}/timeline/section/${timelineId}`;
        Vehicles.VehiclesDetail = (vehicleId) => `/public/vehicles/${vehicleId}`;
    })(Vehicles = urls.Vehicles || (urls.Vehicles = {}));
    let Vehicles2;
    (function (Vehicles2) {
        Vehicles2.ListVehicles2 = () => `/public/vehicles2`;
    })(Vehicles2 = urls.Vehicles2 || (urls.Vehicles2 = {}));
    let Geonets;
    (function (Geonets) {
        Geonets.MapValidationList = () => `/public/geonets/map-validation`;
    })(Geonets = urls.Geonets || (urls.Geonets = {}));
})(urls || (urls = {}));

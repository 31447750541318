import { AnimatePresence, motion } from 'framer-motion';
import { createPortal } from 'react-dom';
import Icon from 'src/components/common/Icon';
import { useHasInternetConnectivity } from 'src/tools/hooks/useHasInternetConnectivity';
import transition from 'src/tools/transition';
import './DisconnectedMessage.scss';

function DisconnectedMessage() {
  const hasInternetConnectivity = useHasInternetConnectivity();

  return createPortal(
    <div className="disconnected-message-container">
      <AnimatePresence>
        {!hasInternetConnectivity && (
          <motion.div
            {...transition({ exit: { y: -100 }, entrance: { y: -100 } })}
            className="disconnected-message"
            role="alert"
          >
            <Icon
              name="TriangleWithExclamation"
              className="disconnected-message__icon"
            />
            <h3 className="disconnected-message__title">
              Command Center can’t connect to the internet
            </h3>
            <p className="disconnected-message__detail">
              Command Center will not update or respond to actions.
              <br />
              Data will be inaccurate until internet connection is
              re-established.
            </p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>,
    document.body,
  );
}

export default DisconnectedMessage;

import clsx from 'clsx';
import { ReactNode } from 'react';
import ButtonField from './ButtonField';
import Icon from './Icon';
import './PanelHeader.scss';

export type Props = {
  className?: string;
  title: string;
  strapline?: ReactNode;
  onBackClick?: () => void;
  onSecondaryActionClick?: () => void;
  renderSecondaryAction?: ReactNode | (() => ReactNode);
  primaryAction?: ReactNode;
};

function PanelHeader({
  className,
  title,
  strapline,
  onBackClick,
  onSecondaryActionClick,
  renderSecondaryAction,
  primaryAction,
}: Props) {
  const actionLabel =
    typeof renderSecondaryAction === 'function'
      ? renderSecondaryAction()
      : renderSecondaryAction;

  return (
    <header className={clsx([className, 'panel-header'])}>
      <hgroup className="panel-header__heading-group">
        <h3 className="panel-header__title">{title}</h3>

        {strapline && <p className="panel-header__strapline">{strapline}</p>}
      </hgroup>

      {primaryAction && (
        <div className="panel-header__primary-action">{primaryAction}</div>
      )}

      {onBackClick && (
        <ButtonField
          size="small"
          color="text"
          className="panel-header__back-button"
          onClick={onBackClick}
          label={
            <Icon className="panel-header__close-icon" name="ChevronLeft" />
          }
        />
      )}

      {onSecondaryActionClick && (
        <ButtonField
          size="small"
          color="text"
          className="panel-header__secondary-action-button"
          onClick={onSecondaryActionClick}
          label={actionLabel || 'Action'}
        />
      )}
    </header>
  );
}

export default PanelHeader;

import { useCallback, useEffect, useRef } from 'react';

const now = () => (performance || Date).now();

interface Props {
  keys: readonly KeyboardEvent['key'][];
  delayMs?: number;
  preventDefault?: boolean;
  minKeysForFailure?: number;
  onSuccess?: (comboDurationMs: number) => void;
  onFailure?: (successfulKeyPresses: number) => void;
}

export const useKeyboardCombo = ({
  keys = [],
  delayMs = 1000,
  preventDefault,
  minKeysForFailure,
  onSuccess,
  onFailure,
}: Props) => {
  const successfulKeyPresses = useRef(0);
  const comboStart = useRef(0);

  const handleFailure = useCallback(() => {
    if (
      onFailure &&
      minKeysForFailure &&
      successfulKeyPresses.current >= minKeysForFailure
    ) {
      onFailure?.(successfulKeyPresses.current);
    }
    successfulKeyPresses.current = 0;
  }, [minKeysForFailure, onFailure]);

  useEffect(
    function intialiseKeyPressHandler() {
      let timeout: NodeJS.Timeout | false = false;

      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key?.toLowerCase() !== keys[successfulKeyPresses.current]) {
          handleFailure();

          successfulKeyPresses.current = 0;
          return;
        }

        if (successfulKeyPresses.current === 0) {
          comboStart.current = now();
        }

        if (preventDefault) {
          event.preventDefault();
          event.stopPropagation();
        }

        if (timeout) {
          clearTimeout(timeout);
        }

        if (successfulKeyPresses.current === keys.length - 1) {
          onSuccess?.(now() - comboStart.current);
          successfulKeyPresses.current = 0;
          comboStart.current = 0;
        } else {
          successfulKeyPresses.current += 1;
          timeout = setTimeout(() => {
            handleFailure();
            timeout = false;
          }, delayMs);
        }
      };

      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    },
    [handleFailure, onSuccess, delayMs, keys, preventDefault],
  );
};

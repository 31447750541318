import { Rgb } from './rgb';

export const hex2Rgb = (hexColor: string): Rgb => {
  const colourNumber = parseInt(hexColor.slice(1), 16);
  return [
    (colourNumber >> 16) & 255,
    (colourNumber >> 8) & 255,
    colourNumber & 255,
  ];
};

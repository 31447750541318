export var urls;
(function (urls) {
    let Provision;
    (function (Provision) {
        Provision.CreateRegistration = () => `/v1/provision/registration`;
        Provision.GetRegistration = (registrationId) => `/v1/provision/registration/${registrationId}`;
    })(Provision = urls.Provision || (urls.Provision = {}));
    let Ota;
    (function (Ota) {
        Ota.SignSshCert = (vehicleId, registrationId) => `/v1/ota/provision/${vehicleId}/sign_ssh_cert/${registrationId}`;
        Ota.SignCsrs = (vehicleId, registrationId, vaultMountPoint) => `/v1/ota/provision/${vehicleId}/${registrationId}/sign_csrs/${vaultMountPoint}`;
        Ota.ProvisionCaCertDetail = (vehicleId, registrationId, vaultMountPoint) => `/v1/ota/provision/${vehicleId}/${registrationId}/ca_cert/${vaultMountPoint}`;
        Ota.CreateVaultSshRoles = (vehicleId, registrationId) => `/v1/ota/provision/${vehicleId}/create_vault_ssh_roles/${registrationId}`;
    })(Ota = urls.Ota || (urls.Ota = {}));
    let Enroll;
    (function (Enroll) {
        Enroll.ServersEnroll = () => `/enroll`;
        Enroll.EnrollVehicle = () => `/enroll`;
    })(Enroll = urls.Enroll || (urls.Enroll = {}));
    let Vehicle;
    (function (Vehicle) {
        Vehicle.ServersVehicle = (vehicleId) => `/vehicle/${vehicleId}/revoke`;
        Vehicle.RevokeVehicle = (vehicleId) => `/vehicle/${vehicleId}/revoke`;
        Vehicle.ServersVehicle2 = (vehicleId) => `/vehicle/${vehicleId}/re-enroll`;
        Vehicle.ReEnrollVehicle = (vehicleId) => `/vehicle/${vehicleId}/re-enroll`;
        Vehicle.ServersVehicle3 = (vehicleId) => `/vehicle/${vehicleId}`;
        Vehicle.DeleteVehicle = (vehicleId) => `/vehicle/${vehicleId}`;
        Vehicle.UpdateVehicle = (vehicleId) => `/vehicle/${vehicleId}`;
    })(Vehicle = urls.Vehicle || (urls.Vehicle = {}));
    let Models;
    (function (Models) {
        Models.ServersModels = (model) => `/models/${model}/platforms`;
        Models.GetModelPlatform = (model) => `/models/${model}/platforms`;
    })(Models = urls.Models || (urls.Models = {}));
    let Health;
    (function (Health) {
        Health.ServersHealth = () => `/health`;
        Health.HealthList = () => `/health`;
    })(Health = urls.Health || (urls.Health = {}));
    let ServiceInfo;
    (function (ServiceInfo) {
        ServiceInfo.ServiceInfoList = () => `/v1/service_info`;
    })(ServiceInfo = urls.ServiceInfo || (urls.ServiceInfo = {}));
})(urls || (urls = {}));

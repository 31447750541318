import { useOktaAuth } from '@okta/okta-react';
import { captureException } from '@sentry/browser';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { INITIAL_URL } from 'src/auth/config';
import FullWidthLoader from 'src/components/common/FullWidthLoader';
import { useMessages } from 'src/components/Messages/messages-context';
import { isNullish } from 'src/tools/types';

function PageNotFound() {
  const { showMessage } = useMessages();
  const oktaAuth = useOktaAuth();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const isAuthenticated = oktaAuth?.authState?.isAuthenticated;

  useEffect(
    function navigateAwayAfterAuthenticationSettles() {
      if (isNullish(isAuthenticated)) return;

      if (isAuthenticated) {
        showMessage({
          type: 'warning',
          title: `${pathname} isn’t a page we know about`,
          description:
            'Here’s the dashboard instead. Try navigating from here!' +
            ' Or press the heart icon top right if you think this is our mistake.',
        });
        captureException(new Error(`Page not found: ${pathname}${search}`));

        navigate('/');
      } else {
        localStorage.setItem(INITIAL_URL, `${pathname}${search}`);
        navigate('/login');
      }
    },
    [isAuthenticated, navigate, pathname, search, showMessage],
  );

  return (
    <div className="the-app__loader">
      <FullWidthLoader />
    </div>
  );
}

export default PageNotFound;

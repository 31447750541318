import clsx from 'clsx';
import { forwardRef, ReactHTML, ReactNode, Ref } from 'react';
import './Card.scss';

export type CardColor = 'info' | 'alert' | 'focus';

export interface Props {
  color?: CardColor;
  isDisabled?: boolean;
  element?: keyof ReactHTML;
  children: ReactNode;
  className?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
}

function BorderedCard(
  {
    color = 'info',
    isDisabled = false,
    element = 'div',
    children,
    className: classNameProps,
    onMouseEnter,
    onMouseLeave,
    onClick,
  }: Props,
  ref: Ref<HTMLElement>,
) {
  const sharedProps = {
    className: clsx([
      classNameProps,
      'the-card',
      `the-card--color-${color}`,
      {
        'the-card--disabled': isDisabled,
        'the-card--button': !!onClick,
      },
    ]),
    ref: ref as Ref<HTMLButtonElement>,
    onMouseEnter,
    onMouseLeave,
  };

  if (onClick) {
    return (
      <button {...sharedProps} type="button" onClick={onClick}>
        {children}
      </button>
    );
  }

  // TODO: Work out how to type this up without this hack
  // Extends to `as Ref<HTMLButtonElement>` below
  const Element = element as 'button';
  return <Element {...sharedProps}>{children}</Element>;
}

export default forwardRef(BorderedCard);

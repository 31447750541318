import {
  VehicleSettingOverrides,
  VehicleSettings,
} from '@motional-cc/fe/interface/api/api-server';
import { useQueryClient } from '@tanstack/react-query';
import { FormEvent, useEffect, useState } from 'react';
import { apiServerPaths } from 'src/api/api-server';
import { useApi, useMutateApi } from 'src/api/hooks/service';
import ButtonField from 'src/components/common/ButtonField';
import Definition from 'src/components/common/Definition';
import EmptyState from 'src/components/common/EmptyState';
import FieldSetWithoutAccessibleLabel from 'src/components/common/FieldSetWithoutAccessibleLabel';
import FullWidthLoader from 'src/components/common/FullWidthLoader';
import Select from 'src/components/common/Select/Select';
import SelectListOptionDualLabel from 'src/components/common/Select/SelectListOptionDualLabel';
import TextField from 'src/components/common/TextField';
import { ApiError, isIncompleteStatus } from 'src/interface/command-center';
import { isNullish } from 'src/tools/types';
import SelectBoolean from './SelectBoolean';
import './EditVehicleSettings.scss';

interface Props {
  carId: string;
  onEditSuccess?: () => void;
}

function EditVehicleSettings({ carId, onEditSuccess }: Props) {
  const queryClient = useQueryClient();
  const {
    result: settingsResponse,
    error: settingsError,
    status: settingsStatus,
  } = useApi<VehicleSettings.GetPublicVehicleOverridesVehicleId.ResponseBody>(
    apiServerPaths.VehicleSettings.GetPublicVehicleOverridesVehicleId(carId),
    {
      knownErrorCodes: ['not_found'],
      retry: false,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  );
  const { result: manifestBehaviors, status: manifestStatus } =
    useApi<VehicleSettings.Manifest.ResponseBody>(
      apiServerPaths.VehicleSettings.Manifest(),
    );
  const { mutate, isLoading } = useMutateApi<
    VehicleSettings.PutPublicVehicleOverridesVehicleId.ResponseBody,
    VehicleSettings.PutPublicVehicleOverridesVehicleId.RequestBody
  >(
    apiServerPaths.VehicleSettings.PutPublicVehicleOverridesVehicleId(carId),
    'PUT',
  );

  const [settings, setSettings] = useState<VehicleSettingOverrides | undefined>(
    settingsResponse,
  );
  useEffect(
    function syncInitialSettings() {
      if (settings || !settingsResponse) return;

      setSettings(settingsResponse);
    },
    [settings, settingsResponse],
  );

  const handleValueChange =
    (settingName: string) =>
    (newValue: string | boolean | number | undefined) => {
      setSettings((oldSettings) => {
        const newSettings = oldSettings ? { ...oldSettings } : {};

        if (isNullish(newValue)) {
          delete newSettings[settingName];
        } else {
          newSettings[settingName] = { value: newValue };
        }

        return newSettings;
      });
    };

  const handleFormSubmit = async (event: FormEvent) => {
    event.preventDefault();

    try {
      await mutate(settings || {});
      onEditSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [
          apiServerPaths.VehicleSettings.GetPublicVehicleOverridesVehicleId(
            carId,
          ),
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          apiServerPaths.VehicleSettings.GetPublicBehaviorsVehicleSummariesVehicleId(
            carId,
          ),
        ],
      });
    } catch (_error) {
      const error = _error as ApiError;
      // eslint-disable-next-line no-console
      console.log({ error });
    }
  };

  if (
    isIncompleteStatus(settingsStatus) ||
    isIncompleteStatus(manifestStatus)
  ) {
    return <FullWidthLoader />;
  }

  // If the error is `not_found` then it likely means that the vehicle has no settings,
  //   not that we can’t set some
  if (settingsError && settingsError.code !== 'not_found') {
    return <EmptyState title="Error fetching settings" />;
  }

  return (
    <div className="edit-vehicle-settings">
      {!manifestBehaviors?.length ? (
        <EmptyState title="Cannot find available settings" />
      ) : (
        <form onSubmit={handleFormSubmit}>
          <FieldSetWithoutAccessibleLabel vertical>
            <ul className="edit-vehicle-settings__setting-list">
              {manifestBehaviors.map((manifestSetting, index) => {
                const value = settings?.[manifestSetting.key]?.value;

                return (
                  <li key={index}>
                    <FieldSetWithoutAccessibleLabel vertical>
                      <Definition
                        title={manifestSetting.description}
                        definition={manifestSetting.detailed_description}
                      />

                      {manifestSetting.control_details.type === 'boolean' ? (
                        <SelectBoolean
                          label={manifestSetting.name}
                          placeholder={`Default (${manifestSetting.control_details.default_value})`}
                          value={value as boolean | undefined}
                          onSelect={handleValueChange(manifestSetting.key)}
                          renderListOption={(value) =>
                            value &&
                            `${manifestSetting.control_details.default_value}` ===
                              `${value}` ? (
                              <SelectListOptionDualLabel
                                primaryLabel={`${value}`}
                                secondaryLabel="Default value"
                              />
                            ) : (
                              value
                            )
                          }
                          renderSelectedOption={(value) =>
                            value &&
                            `${manifestSetting.control_details.default_value}` ===
                              `${value}`
                              ? `${value} (default)`
                              : `${value}`
                          }
                        />
                      ) : manifestSetting.control_details.type === 'enum' ? (
                        <Select
                          allowClear
                          onSelect={handleValueChange(manifestSetting.key)}
                          label={manifestSetting.name}
                          value={value}
                          placeholder={`Default (${manifestSetting.control_details.default_value})`}
                          options={
                            manifestSetting.control_details.constraints
                              ?.values ?? []
                          }
                        />
                      ) : (
                        // TODO: when we have some other types to test with, implement them here
                        // <DecimalField
                        //   label={behavior.name}
                        //   onChange={handleValueChange(behavior.key)}
                        //   placeholder={`default (${behavior.control_details.default_value})`}
                        //   min={behavior.control_details.constraints?.minimum}
                        //   max={behavior.control_details.constraints?.maximum}
                        //   value={
                        //     isRealNumber(summaryValue) ? String(summaryValue) : ''
                        //   }
                        // />
                        <TextField
                          disabled
                          label={manifestSetting.name}
                          placeholder={`${manifestSetting.control_details.type} settings not yet supported`}
                          value=""
                        />
                      )}
                    </FieldSetWithoutAccessibleLabel>
                  </li>
                );
              })}
            </ul>

            <ButtonField
              label="Save"
              type="submit"
              size="medium"
              color="call-to-action"
              disabled={isLoading || !settings}
            />
          </FieldSetWithoutAccessibleLabel>
        </form>
      )}
    </div>
  );
}

export default EditVehicleSettings;

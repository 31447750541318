import { memo, ReactNode, Suspense, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { userApi } from 'src/api/user';
import FullWidthLoader from 'src/components/common/FullWidthLoader';
import useModal from 'src/components/Modal/modal-hook';
import { MapsProvider } from 'src/contexts/maps-context';
import { ProfileSettingsProvider } from 'src/contexts/profile-settings-context';
import { PushNotificationsProvider } from 'src/contexts/push-notifications-context';
import { useTheme } from 'src/contexts/theme-context';
import { useHasToggle } from 'src/tools/hooks/useHasToggle';
import { useKeyboardCombo } from 'src/tools/hooks/useKeyboardCombo';
import { lazyWithRetry } from 'src/tools/lazy-with-retry';

const ColourTester = lazyWithRetry(() => import('src/screens/ColourTester'));

const KONAMI_KEYS = [
  'arrowup',
  'arrowup',
  'arrowdown',
  'arrowdown',
  'arrowleft',
  'arrowright',
  'arrowleft',
  'arrowright',
  'b',
  'a',
  'enter',
] as const;

const TOGGLE_EDITOR_KEYS = [
  'shift',
  'shift',
  'shift',
  'e',
  'd',
  'i',
  't',
  ' ',
  't',
  'o',
  'g',
  'g',
  'l',
  'e',
  's',
] as const;

const DOOM_GOD_MODE_KEYS = ['shift', 'i', 'd', 'd', 'q', 'd'] as const;

const COLOUR_EDITOR_KEYS = [
  'shift',
  'shift',
  'shift',
  'e',
  'd',
  'i',
  't',
  ' ',
  'c',
  'o',
  'l',
  'o',
  'u',
  'r',
  's',
] as const;

let unbleedScreenTimeout: NodeJS.Timeout | false = false;
const bleedScreen = () => {
  if (unbleedScreenTimeout) {
    clearTimeout(unbleedScreenTimeout);
  }

  document.documentElement.style.setProperty(
    '--document-filter',
    `
    sepia(0.6)
    brightness(0.5)
    saturate(1.2)
    hue-rotate(-50deg)`,
  );

  unbleedScreenTimeout = setTimeout(() => {
    unbleedScreenTimeout = false;
    document.documentElement.style.setProperty('--document-filter', 'none');
  }, 4000);
};

interface Props {
  children: ReactNode;
}

function Authenticated({ children }: Props) {
  const { userProfile } = userApi.useUserProfile();
  const enabledZoomOnBigScreens = useHasToggle('ENABLE_ZOOM_ON_BIG_SCREENS');
  const { setTheme } = useTheme();
  const navigate = useNavigate();

  const { ModalComponent: ColourTestModal, openModal: openColorTestModal } =
    useModal();

  const goToToggles = useCallback(
    (duration: number) => {
      // eslint-disable-next-line no-console
      console.log(
        `You took ${duration / 1000} seconds to type the Konami code!`,
      );
      navigate('/role-management/custom-user-settings/manage-toggles');
    },
    [navigate],
  );

  useKeyboardCombo({
    keys: KONAMI_KEYS,
    minKeysForFailure: 8,
    delayMs: 400,
    onSuccess: goToToggles,
    onFailure: bleedScreen,
  });

  useKeyboardCombo({
    keys: TOGGLE_EDITOR_KEYS,
    minKeysForFailure: 12,
    delayMs: 700,
    onSuccess: goToToggles,
    onFailure: bleedScreen,
  });

  useKeyboardCombo({
    keys: DOOM_GOD_MODE_KEYS,
    minKeysForFailure: 5,
    delayMs: 400,
    onSuccess: openColorTestModal,
    onFailure: bleedScreen,
  });

  useKeyboardCombo({
    keys: COLOUR_EDITOR_KEYS,
    minKeysForFailure: 12,
    delayMs: 700,
    onSuccess: openColorTestModal,
    onFailure: bleedScreen,
  });

  useEffect(
    function setUserProfileTheme() {
      if (userProfile?.theme) {
        setTheme(userProfile?.theme);
      }
    },
    [userProfile?.theme, setTheme],
  );

  useEffect(
    function enableZoomOnBigScreens() {
      const bigScreenZoomClass = 'user-has-toggle--zoom-on-big-sreens';

      if (enabledZoomOnBigScreens) {
        document.documentElement.classList.add(bigScreenZoomClass);
      } else {
        document.documentElement.classList.remove(bigScreenZoomClass);
      }
    },
    [enabledZoomOnBigScreens],
  );

  return (
    <MapsProvider>
      <ProfileSettingsProvider>
        <PushNotificationsProvider>
          {children}
          <ColourTestModal isOpen title="Colour editor">
            <Suspense fallback={<FullWidthLoader />}>
              <ColourTester />
            </Suspense>
          </ColourTestModal>
        </PushNotificationsProvider>
      </ProfileSettingsProvider>
    </MapsProvider>
  );
}

export default memo(Authenticated);
